import Vue from 'vue'
import Vuex from "vuex";
import imHere from "./modules/imHere";
import toast from "./modules/toast";
import {getJsonData} from "../services/fetchApi";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        imHere: imHere,
        toast: toast
    },
    state: {
        loadingAnything: false,
        orderInfo: [],
        gettingDictionaries: null,
        dictionaries: null,
        user: null,
        rma: null,
        rmaHistoryLoading: false,
        rmaHistory: null,
        rmaCommentsLoading: false,
        rmaComments: null
    },
    mutations: {
        setLoadingAnything(state, data) {
            state.loadingAnything = data;
        },
        setOrderInfo (state, data) {
            state.orderInfo.push(data);
        },
        setDictionaries (state, data) {
            state.dictionaries = data;
        },
        gettingDictionaries(state, bool) {
            state.gettingDictionaries = bool;
        },
        setUser(state, user) {
            state.user = user;
        },
        setRma(state, rma) {
            state.rma = rma;
        },
        setRmaHistory(state, rmaHistory) {
            if (state.rma) {
                state.rmaHistory = { ...state.rmaHistory, newProp: state.rma.id }
                state.rmaHistory[state.rma.id] = rmaHistory;
            }
        },
        setRmaHistoryLoading(state, bool) {
            state.rmaHistoryLoading = bool;
        },
        setRmaComments(state, rmaComments) {
            if (state.rma) {
                state.rmaComments = { ...state.rmaComments, newProp: state.rma.id }
                state.rmaComments[state.rma.id] = rmaComments;
            }
        },
        setRmaCommentsLoading(state, bool) {
            state.rmaCommentsLoading = bool;
        }
    },
    getters: {
        isLoadingAnything: state => {
          return state.loadingAnything;
        },
        getUserName: state => {
            if (state.user) {
                return state.user.username
            } else {
                return null;
            }
        },
        getUserRoles: state => {
            if (state.user) {
                return state.user.roles;
            } else {
                return [];
            }
        },
        isTokenValid: state => {
            if (state.user) {
                return state.user.exp * 1000 > Date.now()
            } else {
                return null;
            }
        },
        getRmaHistory: state => {
            if (state.rma && state.rmaHistory) {
                return state.rmaHistory[state.rma.id];
            } else {
                return [];
            }
        },
        getRmaHistoryLoading: state => {
            return state.rmaHistoryLoading;
        },
        getRmaComments: state => {
            if (state.rma && state.rmaComments) {
                return state.rmaComments[state.rma.id];
            } else {
                return [];
            }
        },
        getRmaCommentsLoading: state => {
            return state.rmaCommentsLoading;
        },
        getRma: state => {
            return state.rma;
        },
        getDictionaries: state => {
            return state.dictionaries;
        }
    },
    actions: {
        getDictionaries(context) {
            context.commit('gettingDictionaries', true);
            return getJsonData(process.env.VUE_APP_BACKEND_SERVER + 'api/customer_dictionaries')
                .then(json => {
                    let dictionaries = {};
                    Object.keys(json).forEach(dictionary => {
                        dictionaries[dictionary] = Object.keys(json[dictionary]).map(k => ({value: k, text: json[dictionary][k]}))
                    });
                    context.commit('setDictionaries', dictionaries);
                })
                .catch(function (error) {
                    return context.dispatch('showToast', {
                        title: 'Can not get dictionaries',
                        message: error.toString(),
                        type: 'danger'
                    });
                })
                .finally(() => context.commit('gettingDictionaries', false));
        },
        getRmaHistory(context) {
            context.commit('setRmaHistoryLoading', true);
            getJsonData(
                process.env.VUE_APP_BACKEND_SERVER + 'api/returns/' + context.getters.getRma.rmaNumber + '/history'
            )
                .then(json => {
                    context.commit('setRmaHistory', json.history);
                }).catch(error => {
                    context.commit('showToast', {
                        message: error,
                        title: 'Can not load history of the rma changes',
                        type: 'danger'
                    }, {root: true});
                })
                .finally(() => {
                    context.commit('setRmaHistoryLoading', false);
                });
        },
        getRmaComments(context) {
            context.commit('setRmaCommentsLoading', true);
            getJsonData(
                process.env.VUE_APP_BACKEND_SERVER + 'api/returns/' + context.getters.getRma.rmaNumber + '/comments'
            )
                .then(json => {
                    context.commit('setRmaComments', json);
                }).catch(error => {
                    context.commit('showToast', {
                        message: error,
                        title: 'Can not load commentaries',
                        type: 'danger'
                    }, {root: true});
                })
                .finally(() => {
                    context.commit('setRmaCommentsLoading', false);
                });
        },
        showToast(context, toast) {
            context.dispatch('toast/showToast', toast);
        }
    }
})


export default store;