<template>
  <b-form method="post" :action="actionApiUrl" @submit.prevent="updateShippingAddress($event)">
    <b-overlay :show="sendingShippingAddress">
      <b-table-simple class="mb-0" responsive="true" borderless small>
        <b-tr><b-th>{{ $t('Name') }}:</b-th><b-td colspan="3" class="text-break">
          <b-input-group v-if="edit">
            <b-form-select class="salutation-select" v-model="model.salutation" :options="salutations" size="sm">
              <template #first>
                <b-form-select-option :value="null"></b-form-select-option>
              </template>
            </b-form-select>
            <b-input required v-model="model.firstName" size="sm"></b-input>
            <b-input required v-model="model.lastName" size="sm"></b-input>
          </b-input-group>
          <span v-else>{{ model.salutation ? $t(`salutations.${model.salutation}`) : '' }} {{ model.firstName }} {{ model.lastName }}</span>
        </b-td></b-tr>
        <b-tr><b-th>{{ $t('City') }}:</b-th><b-td colspan="3" class="text-break">
          <b-input-group v-if="edit">
            <b-input required v-model="model.city" size="sm"></b-input>
            <b-form-select v-if="edit" required v-model="model.countryCode" :options="dictionaries.countries" size="sm"></b-form-select>
          </b-input-group>
          <span v-else>{{ model.city }}, {{ getValueText(model.countryCode, 'countries') }}</span>
        </b-td></b-tr>
        <b-tr>
          <b-th>{{ $t('Zip') }}:</b-th><b-td :colspan="region === '02' ? 1 : 3">
            <b-input v-if="edit" required v-model="model.zipCode" size="sm"></b-input>
            <span v-else>{{ model.zipCode }}</span>
          </b-td>
          <b-th v-if="region === '02'">{{ $t('State') }}:</b-th>
          <b-td v-if="region === '02'" class="text-break">
            <b-form-select v-if="edit" required v-model="model.state" :options="dictionaries.usaStates" size="sm"></b-form-select>
            <span v-else>{{ getValueText(model.state, 'usaStates') }}</span>
          </b-td>
        </b-tr>
        <b-tr><b-th>{{ $t('Address') }}:</b-th><b-td colspan="3" class="text-break">
          <b-input v-if="edit" required v-model="model.address" size="sm"></b-input>
          <span v-else>{{ model.address }}</span>
        </b-td></b-tr>
        <b-tr><b-th>{{ $t('Phone') }}:</b-th><b-td colspan="3" class="text-break">
          <b-input v-if="edit" required v-model="model.phone" size="sm"></b-input>
          <span v-else>{{ model.phone }}</span>
        </b-td></b-tr>
        <b-tr v-if="!edit"><b-th>{{ $t('Email') }}:</b-th><b-td colspan="3" class="text-break">{{ email }}</b-td></b-tr>
        <b-tr v-if="edit"><b-td></b-td><b-td>
          <b-button type="submit" :variant="buttonVariant" size="sm">{{ $t('Update') }}</b-button>
        </b-td></b-tr>
      </b-table-simple>
    </b-overlay>
  </b-form>
</template>

<script>
import {postJsonData} from "../services/fetchApi";
import {Salutation as SalutationEnum} from "../enum/Salutation";

export default {
  name: "RmaShippingAddress",
  props: ['value', 'buttonVariant', 'actionApiUrl', 'edit', 'region', 'email'],
  data() {
    return {
      model: {
        salutation: null,
        firstName: null,
        lastName: null,
        countryCode: null,
        region: null,
        zipCode: null,
        state: null,
        address: null,
        phone: null,
      },
      sendingShippingAddress: false,
    }
  },
  mounted() {
    this.$set(this, 'model', this.value);
  },
  methods: {
    updateShippingAddress(e) {
      const $this = this;
      this.sendingShippingAddress = true;
      $this.$store.commit('setLoadingAnything', true);
      postJsonData(
          e.target.action,
          JSON.stringify(this.model)
      )
          .then(json => {
            $this.$set($this, 'model', json.rma.shippingAddress)
            $this.$store.dispatch('getRmaHistory');
            $this.$store.commit('showToast', {
              message: $this.$t('Shipping address has been updated'),
              title: $this.$t('Success'),
              type: 'success'
            });
          })
          .catch(error => {
            $this.$store.commit('showToast', {
              message: $this.$t('Shipping address has not been changed') + ': ' + (error ?? $this.$t('Unknown error')),
              title: $this.$t('Error'),
              type: 'danger'
            });
          })
          .finally(() => {
            $this.sendingShippingAddress = false;
            $this.$store.commit('setLoadingAnything', false);
          });
      return false;
    },
    getValueText(value, dictionary) {
      if (!this.dictionaries || !dictionary || !(dictionary in this.dictionaries)) {
        return '';
      }
      let found = this.dictionaries[dictionary].find(item => item.value === value);
      return found ? found.text : '';
    }
  },
  computed: {
    dictionaries: function () {
      if (!this.$store.state.dictionaries) {
        return [];
      }
      return this.$store.state.dictionaries;
    },
    salutations: function () {
      const $this = this;
      return Object.entries(SalutationEnum).map(val => ({value: val[0], text: $this.$t(`salutations.${val[1]}`)}));
    }
  },
  watch: {
    value() {
      this.$set(this, 'model', this.value);
    },
    model() {
      this.$emit('input', this.model);
    },
  }
}
</script>

<style scoped>

</style>