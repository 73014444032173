<template>
  <b-modal
      id="confirm-send-to-spedimex"
      :title="$t('Confirm sending next products to Spedimex')"
      size="xl"
      @ok="sendRmaToSpedimex"
      @shown="cleanSendToSpedimexModal"
  >
    <template #modal-ok>
      <b-spinner small :class="{'spinner-border mx-2': 1, 'd-none': !rmaToSpedimexSending}"></b-spinner>
      <span :class="{'d-none': rmaToSpedimexSending}">{{ $t('Send') }}</span>
    </template>
    <template #modal-cancel>{{ $t('Close') }}</template>
    <b-alert variant="danger" :show="Array.isArray(spedimexResponseErrors)">
      <p v-for="(error, i) in spedimexResponseErrors" :key="i">{{ error }}</p>
    </b-alert>
    <b-table-simple small responsive>
      <b-tr>
        <b-th>{{ $t('Item')}} {{ $t('Sku')}} ({{ $t('Size')}}) {{ $t('Ean') }}</b-th>
        <b-th>{{ $t('Status')}}</b-th>
        <b-th>{{ $t('SPX DocNo')}}</b-th>
        <b-th>{{ $t('Ean')}} <b-icon icon="question-circle-fill" v-b-tooltip.hover title="By default it's an ean from the order. If empty then an ean will be gotten from the order. If change the value then SPX return advice will be created with the new one value. When SPX return advice is created and a checkbox Re-Transmit is checked after saving the form for this rma product will be created a new doc in SPX with the new one ean."></b-icon></b-th>
        <b-th>{{ $t('Tracking Number')}}</b-th>
        <b-th>{{ $t('Quantity')}}</b-th>
        <b-th>{{ $t('Quality')}}</b-th>
        <b-th>{{ $t('Re-Transmit')}} <b-icon icon="question-circle-fill" v-b-tooltip.hover title="If checked, the product will be re-transmitted to SPX. The previous return advice in SPX will continue to exists. The new one for this RMA product will be created. New return advice will be connected to this RMA product and after receiving confirmation with quality A, if the status is Authorized, the status of the RMA product will be automatically updated."></b-icon></b-th>
      </b-tr>
      <b-tr v-for="(product, productIndex) in value.filter(el => this.selected.includes(el.id))" :key="product.id" :variant="product.spedimexReturnItem ? (product.spedimexReturnItem.quantityReceived !== null ? 'success' : 'secondary') : ''">
        <b-td><span class="text-nowrap">#{{ product.productNumber }} {{ product.sku }} ({{ product.size }})</span> <br> {{ product.ean }}</b-td>
        <b-td>{{ product.status }}</b-td>
        <b-td><span class="text-nowrap">{{ product.spedimexReturnItem ? product.spedimexReturnItem.docNo : '' }}</span></b-td>
        <b-td><b-form-input v-model.trim="model.items[productIndex].ean"></b-form-input> </b-td>
        <b-td>{{ product.spedimexReturnItem ? product.spedimexReturnItem.trackingNumber : '' }}</b-td>
        <b-td>{{ product.spedimexReturnItem ? product.spedimexReturnItem.quantityReceived : '' }}</b-td>
        <b-td>{{ product.spedimexReturnItem ? product.spedimexReturnItem.qualityReceived : '' }}</b-td>
        <b-td><b-form-checkbox v-model="model.items[productIndex].reTransmit"></b-form-checkbox></b-td>
      </b-tr>
    </b-table-simple>
    <b-form-checkbox inline v-model="model.ignoreValidation">{{ $t('Ignore Validation') }}</b-form-checkbox> <b-icon icon="question-circle-fill" v-b-tooltip.hover title="Don't check RMA region and country, don't check rma product franchise and status"></b-icon>
  </b-modal>
</template>

<script>
import {postJsonData} from "../services/fetchApi";

export default {
  name: "SendRmaToSpedimexModal",
  props: {
    value: {
      type: Array,
      required: true
    },
    selected: {
      type: Array,
      required: true
    },
    rmaNumber: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      rmaToSpedimexSending: false,
      spedimexResponseErrors: null,
      model: {
        ignoreValidation: false,
        items: []
      }
    }
  },
  mounted() {
    this.initModel();
  },
  methods: {
    initModel() {
      const $this = this;
      $this.value.filter(el => $this.selected.includes(el.id)).forEach(function (product, index) {
        $this.$set($this.model.items, index, Object.assign({}, {
            productId: product.id,
            ean: product.spedimexReturnItem ? product.spedimexReturnItem.ean.toString() : (product.ean ? product.ean.toString() : ''),
            reTransmit: false
          })
        );
      });
    },
    cleanSendToSpedimexModal() {
      const $this = this;
      $this.sendToSpedimexNoValidate = false;
      $this.spedimexResponseErrors = null;
      $this.model.ignoreValidation = false;
    },
    sendRmaToSpedimex(e) {
      e.preventDefault();
      const $this = this;
      $this.spedimexResponseErrors = null;
      $this.rmaToSpedimexSending = true;
      $this.$store.commit('setLoadingAnything', true);
      postJsonData(
          process.env.VUE_APP_BACKEND_SERVER + 'api/returns/' + this.rmaNumber + '/sendToSpedimex',
          JSON.stringify($this.model),
      )
          .then(json => {
            if (json.spedimexReturn && Array.isArray(json.spedimexReturn.items)) {
              json.spedimexReturn.items.map(item => {
                $this.value.filter(el => el.id === item.rmaProductId).forEach(el => {
                  el.spedimexReturnDocNo = json.spedimexReturn.docNo;
                  el.spedimexReturnItem = item;
                });
              })

              $this.$emit('input', $this.value)
            }

            if (json.errors && Array.isArray(json.errors) && json.errors.length > 0) {
              $this.spedimexResponseErrors = json.errors.join('\r\n');
              $this.$store.commit('showToast', {
                message: $this.$t('Can not send Rma to Spedimex') + ':' + $this.spedimexResponseErrors,
                title: $this.$t('Spedimex'),
                type: 'danger'
              });
            } else {
              $this.$store.commit('showToast', {
                message: $this.$t('Rma was sent to Spedimex'),
                title: $this.$t('Spedimex'),
                type: 'success'
              });

              $this.$bvModal.hide('confirm-send-to-spedimex');
            }
          })
          .catch(error => {
            $this.$store.commit('showToast', {
              message: $this.$t('Can not send Rma to Spedimex') + ':' + (error ?? $this.$t('Unknown error')),
              title: $this.$t('Spedimex'),
              type: 'danger'
            });
          })
          .finally(() => {
            $this.rmaToSpedimexSending = false;
            $this.$store.commit('setLoadingAnything', false);
          });
      return false;
    },
  },
  watch: {
    value() {
      this.initModel();
    },
    selected() {
      this.initModel();
    }
  }
}
</script>

<style scoped>

</style>