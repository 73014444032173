<template>
  <b-overlay :show="uploadingInProccess">
    <b-form-file
        multiple
        accept="image/*"
        v-model="photoFiles"
        :state="needToUpload === null ? null : needToUpload"
        :required="needToUpload === null ? null : !needToUpload"
        style="width: 80px;"
    ></b-form-file>
  </b-overlay>
</template>
<script>
import {postData} from "../services/fetchApi";

export default {
    props: ['rmaNumber', 'itemId', 'value', 'needToUpload'],
  data() {
    return {
      uploadingInProccess: false,
      photoFiles: null
    }
  },
  watch: {
    'photoFiles': function (newValue) {this.uploadWarehousePhoto(newValue);}
  },
  methods: {
    uploadWarehousePhoto(files) {
      const $this = this;
      const fd = new FormData();
      if (!files || !Array.isArray(files) || files.length === 0) {
        return;
      }
      files.forEach((file, index) => fd.append('files[' + this.itemId + '][' + index + ']', file));
      $this.uploadingInProccess = true;
      $this.$store.commit('setLoadingAnything', true);
      postData(process.env.VUE_APP_BACKEND_SERVER + 'api/returns/' + this.rmaNumber + '/uploadWarehousePhotos', fd)
          .then(json => {
            $this.$emit('input', json)
            $this.$store.commit('showToast', {
              message: $this.$t('Photo successfully uploaded'),
              title: $this.$t('Uploading photo'),
              type: 'success'
            });
            $this.$store.dispatch('getRmaHistory');
          })
          .catch(error => {
            $this.$store.commit('showToast', {
              message: error ?? $this.$t('Unknown error'),
              title: $this.$t('Can not upload photo'),
              type: 'danger'
            });
          })
          .finally(() => {
            $this.uploadingInProccess = false;
            $this.$store.commit('setLoadingAnything', false);
          })
    }
  }
}
</script>