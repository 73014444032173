<template>
  <b-form method="post" :action="actionApiUrl" @submit.prevent="updatePaymentInfo($event)">
    <b-overlay :show="sendingPaymentInfo">
      <b-form-group v-if="model.fullName || edit" :label="$t('Full Name') + ':'" label-cols-sm="4" label-size="sm" label-align="right" class="mt-2 mb-0">
        <b-input :plaintext="!edit" required v-model="model.fullName" size="sm"></b-input>
      </b-form-group>
      <b-form-group v-if="model.bankName || edit" :label="$t('Bank') + ':'" label-cols-sm="4" label-size="sm" label-align="right" class="mb-0">
        <b-input :plaintext="!edit" required v-model="model.bankName" size="sm"></b-input>
      </b-form-group>
      <b-form-group v-if="model.iban || edit" :label="$t('IBAN') + ':'" label-cols-sm="4" label-size="sm" label-align="right" class="mb-0">
        <b-input :plaintext="!edit" required v-model="model.iban" size="sm"></b-input>
      </b-form-group>
      <b-form-group v-if="model.bic || edit" :label="$t('BIC') + ':'" label-cols-sm="4" label-size="sm" label-align="right" class="mb-2">
        <b-input :plaintext="!edit" required v-model="model.bic" size="sm"></b-input>
      </b-form-group>

      <b-row><b-col offset="4 text-left"><b-button type="submit" v-if="edit" :variant="buttonVariant" size="sm">{{ $t('Update') }}</b-button></b-col></b-row>
    </b-overlay>
  </b-form>
</template>

<script>
import {postJsonData} from "../services/fetchApi";

export default {
  name: "RmaPaymentBankAccount",
  props: ['value', 'buttonVariant', 'actionApiUrl', 'edit'],
  data() {
    return {
      model: {
        fullName: null,
        bankName: null,
        iban: null,
        bic: null,
      },
      sendingPaymentInfo: false,
    }
  },
  mounted() {
    this.$set(this, 'model', this.value);
  },
  methods: {
    updatePaymentInfo(e) {
      const $this = this;
      $this.sendingPaymentInfo = true;
      $this.$store.commit('setLoadingAnything', true);
      postJsonData(e.target.action, JSON.stringify(this.model))
          .then(json => {
            $this.$set($this, 'model', json.rma.paymentBankAccount)
            $this.$store.commit('showToast', {
              message: $this.$t('Customer bank account details have been successfully updated'),
              title: $this.$t('Uploading customer bank account details'),
              type: 'success'
            });
            $this.$store.dispatch('getRmaHistory');
          })
          .catch(error => {
            $this.$store.commit('showToast', {
              message: $this.$t('Can\'t update customer bank account details') + ': ' + error ?? $this.$t('Unknown error'),
              title: $this.$t('Uploading customer bank account details'),
              type: 'danger'
            });
          })
          .finally(() => {
            $this.sendingPaymentInfo = false;
            $this.$store.commit('setLoadingAnything', false);
          });
      return false;
    },
  },
  watch: {
    value() {
      this.$set(this, 'model', this.value);
    },
    model() {
      this.$emit('input', this.model);
    },
  }
}
</script>

<style scoped>
</style>