<template>
  <div class="text-nowrap">
    <span v-if="userHasRole('ROLE_CAN_SEE_FINANCE_INFO')"><b-link :href="dashboardProductUrl(article)" target="_blank">{{ article }}</b-link>_<b-link :href="dashboardProductUrl(article + '_' + colorCode)" target="_blank">{{ colorCode }}</b-link></span>
    <span v-else-if="userHasRole('ROLE_WAREHOUSE')"><b-link :href="dashboardProductUrlWh(article)" target="_blank">{{ article }}</b-link>_<b-link :href="dashboardProductUrlWh(article + '_' + colorCode)" target="_blank">{{ colorCode }}</b-link></span>
    <span v-else><b-link target="_blank" :href="productUrl">{{ article }}_{{ colorCode }}</b-link></span>
    <b-button size="sm" variant="link" class="text-secondary" @click="copyToClipboard(article + '_' + colorCode)"><b-icon-clipboard-check></b-icon-clipboard-check></b-button>
  </div>
</template>

<script>
import UserCanMixin from "../mixins/UserCanMixin";

export default {
  mixins: [UserCanMixin],
  name: "DashboardProductLink",
  props: ['article', 'colorCode', 'productUrl'],
  methods: {
    dashboardProductUrl(skuPart) {
      return 'https://dashboard.plein-lab.com/catalog?query_sku=' + skuPart + '&form_type_get=get&query_env=staging';
    },
    dashboardProductUrlWh(skuPart) {
      return 'https://dashboard.plein-lab.com/catalog/product?query_sku=' + skuPart + '&form_type_get=get&query_env=staging';
    },
    async copyToClipboard(copyText) {
      await navigator.clipboard.writeText(copyText);
    }
  }
}
</script>

<style scoped>

</style>