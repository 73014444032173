import VueRouter from "vue-router";
import routes from "./routes";
import Vue from "vue";
import qs from 'qs';

Vue.use(VueRouter)

const router = new VueRouter({
    routes,
    mode: "history",
    parseQuery: query => {
        return qs.parse(query);
    },
    stringifyQuery(query) {
        let result = qs.stringify(query, {encode: false});
        return result ? ('?' + result) : '';
    }
});

export default router;