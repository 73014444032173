<template>
  <div id="app">
    <b-navbar v-if="isLoggedIn" type="dark" variant="dark" class="w-100 flex-fill">
      <b-navbar-nav class="col-3 align-items-center">
        <b-nav-item  :to="{ name: 'returns' }">
          <img :src="require('@/assets/logo.png')" :class="{'animatedAuthLogo': loadingAnything}" style="filter: invert();" alt="RMS" width="40"/>
        </b-nav-item>
        <b-nav-item :to="{ name: 'returns' }">{{ $t('RMS') }}</b-nav-item>
        <b-nav-item v-if="userHasRole('ROLE_CAN_CREATE_RETURN')" :to="{ name: 'findOrder' }">{{ $t('Create Return') }}</b-nav-item>
        <b-nav-item v-if="userHasRole('ROLE_ADMIN')" :to="{ name: 'users' }">{{ $t('Users') }}</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="col-6">
        <b-nav-form v-if="userHasRole('ROLE_CAN_CREATE_RETURN')" form-class="w-100" class="flex-grow-1 col-8 mx-auto"  method="post" :action="getActionUrl.findOrder" @submit.prevent="findOrder">
          <b-overlay :show="sendingFindOrder" rounded="sm" class="w-100">
            <b-input-group>
              <b-form-input v-model="form.sourceOrderId" :placeholder="$t('Order ID')" name="sourceOrderId" @input="form.sourceOrderId = form.sourceOrderId.toUpperCase()" required></b-form-input>
              <b-input-group-append>
                <b-button variant="outline-light" type="submit" :disabled="sendingFindOrder">
                  <b-icon icon="search"></b-icon>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-overlay>
        </b-nav-form>
<!--        <b-nav-item :to="{ name: 'findOrder' }">Find Order</b-nav-item>-->
      </b-navbar-nav>
      <b-navbar-nav class="col-3">
        <b-nav-item-dropdown class="ml-auto" :text="$t('languages.' + currentLocale) + ' (' + currentLocale + ')'" right>
          <b-dropdown-item v-for="locale in locales" :key="locale" @click="changeLocale(locale)">{{ $t('languages.' + locale) + ' (' + locale + ')' }}</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item @click="clearAuth">{{ $t('Log Out') }} <b-icon icon="door-open"></b-icon></b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <main class="mt-3">
      <router-view />
    </main>
  </div>
</template>
<script>
import {clearJwtLocalStorage} from "./services/JwtPayload";
import findOrderMixin from "./mixins/findOrderMixin";
import UserCanMixin from "./mixins/UserCanMixin";

export default {
  name: 'App',
  mixins: [
    findOrderMixin, UserCanMixin
  ],
  created() {
    const getLang = 'lang' in this.$route.query ? this.$route.query.lang : ('locale' in localStorage ? localStorage.locale : null);
    if (getLang && this.locales.includes(getLang)) {
      this.changeLocale(getLang);
    }
  },
  methods:{
    clearAuth() {
      clearJwtLocalStorage();
      this.$store.commit('setUser', null);
      if (this.$router.currentRoute.name !== 'login') {
        this.$router.push('login')
      }
    },
    changeLocale(locale) {
      localStorage.locale = locale;
      this.$i18n.locale = locale;
    }
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isTokenValid;
    },
    loadingAnything: function () {
      return this.$store.getters.isLoadingAnything;
    },
    locales(){
      return Object.keys(this.$i18n.messages)
    },
    currentLocale() {
      return this.$i18n.locale;
    }
  },
  watch: {
    // '$i18n.locale'() {
    //   document.title = this.$route.meta.title(this.$route)
    // },
    '$route': {
      handler(to) {
        document.title = to.meta.title(to)
      },
      immediate: true
    }
  },
}
</script>

<style>
body {
  background-color: #fffefc;
  overflow-y: scroll;
}
#app {
  color: #2c3e50;
}
</style>
