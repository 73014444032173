export default {
    methods: {
        userHasRole(role) {
            return Array.isArray(this.userRoles) && this.userRoles.includes(role);
        }
    },
    computed: {
        userRoles: function () {
            return this.$store.getters.getUserRoles;
        }
    }
}