import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Vue from 'vue'
import App from './App.vue'

import { BootstrapVue, FormPlugin, BootstrapVueIcons } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(FormPlugin)
Vue.use(BootstrapVueIcons)

import store from "./store/store";
import i18n from "@/i18n";
import router from "./router/router";
import {getPayloadFromJwt, getJwtTokenLocalStorage, clearJwtLocalStorage} from "./services/JwtPayload";

router.beforeEach((to, from, next) => {
  store.commit('setUser', getPayloadFromJwt(getJwtTokenLocalStorage()));
  const publicPages = ['login'];
  if (!publicPages.includes(to.name)) {
    if (!store.getters.isTokenValid) {
      clearJwtLocalStorage();
      store.commit('setUser', null);
      return next({name: 'login'});
   } else {
      if ('roles' in to.meta) {
        if (!to.meta.roles.filter(value => store.getters.getUserRoles.includes(value)).length) {
          return;
        }
      }
      if (!store.state.dictionaries) {
        store.dispatch('getDictionaries');
      }
    }
  }

  next();
});
router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title(to)   + ' | RMS'
  })
})


Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  beforeCreate() {
    store.subscribe((mutation) => {
      if (mutation.type !== 'showToast') {
        return;
      }

      let message = mutation.payload.message;

      if (message.indexOf('Expired JWT Token') > -1) {
        //let href = $this.$router.resolve({name: 'login'}).href;
        message = this.$t('Please, log in again');
      }

      this.$bvToast.toast(message, {
        title: mutation.payload.title,
        variant: mutation.payload.type,
        autoHideDelay: 5000,
        appendToast: true,
        toaster: 'b-toaster-bottom-right'
      });
    })
  }
}).$mount('#app');
