const RmaStatus = Object.freeze({
    REQUESTED: 'REQUESTED',
    AUTHORIZED: 'AUTHORIZED',
    RECEIVED: 'RECEIVED',
    APPROVED: 'APPROVED',
    NOT_PROCESSED: 'NOT_PROCESSED',
    DECLINED: 'DECLINED',
    PROCESSED: 'PROCESSED',
    PENDING: 'PENDING'
});

export {RmaStatus}