<template>
  <i :class="{'eyes': 1, 'open': isHereSomebodyElse}" :title="getUsers"><b-icon icon="eye" variant="warning"></b-icon></i>
</template>
<script>
export default {
  data() {
    return {
      interval: null,
      listOfUsers: []
    }
  },
  created() {
    const $this = this;
    this.interval = setInterval(() => {
      $this.sendUpdate();
    }, 5000)
  },
  beforeDestroy () {
    clearInterval(this.interval);
  },
  methods: {
    sendUpdate() {
      this.$store.dispatch('imHere/getUsers');
    }
  },
  computed: {
    getUsers() {
      return this.$store.getters["imHere/getUsers"];
    },
    isHereSomebodyElse() {
        return !!(this.getUsers.length > 0
            && (
                (this.getUsers.includes(this.getUsername) && this.getUsers.length > 1)
                || !this.getUsers.includes(this.getUsername)
            ));
    },
    getUsername() {
      return this.$store.getters.getUserName;
    },
    getRmaNumber: function () {
      return this.$store.getters.getRma.rmaNumber;
    },
  }
}
</script>
<style scoped>
@keyframes blink {
  0% {
    transform: scaleY(1);
    animation-timing-function: ease-in;
  }
  2% {
    transform: scaleY(0.1);
    animation-timing-function: ease-in;
  }
  4% {
    transform: scaleY(1);
    animation-timing-function: ease-out;
  }
  60% {
    transform: scaleY(1);
    animation-timing-function: ease-in;
  }
  61% {
    transform: scaleY(0.1);
    animation-timing-function: ease-in;
  }
  62% {
    transform: scaleY(1);
    animation-timing-function: ease-out;
  }
  63% {
    transform: scaleY(0.1);
    animation-timing-function: ease-in;
  }
  64% {
    transform: scaleY(1);
    animation-timing-function: ease-out;
  }
  100% {
    transform: scaleY(1);
    animation-timing-function: ease-out;
  }
}

.eyes {
  display: inline-block;
  transform: scaleY(0);
  font-size: 2.5rem;
}
.eyes.open {
  transition: transform 0.6s ease-out;
  transform: scaleY(1);
  animation: blink 10s 2s linear infinite;
}
</style>