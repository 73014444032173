<template>
  <b-container fluid>
    <b-sidebar
        id="sidebar-filter"
        :title="$t('Filter')"
        backdrop-variant="white"
        backdrop
        shadow
        width="500px"
    >
      <b-form @submit.prevent="setFilter">
        <b-container>
          <b-form-row>
            <b-col cols="12">
              <b-form-group>
                <b-form-tags input-id="rmaNumbers-tags" v-model="filterForm.rmaNumbers" separator=" ,;" :placeholder="$t(filterFormFields.rmaNumber.name)" add-on-change></b-form-tags>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <b-form-tags input-id="orderNumbers-tags" v-model="filterForm.orderNumbers" separator=" ,;" :placeholder="$t(filterFormFields.orderNumber.name)" add-on-change></b-form-tags>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <b-form-datepicker
                    :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                    :placeholder="$t(filterFormFields.createdAtFrom.name)"
                    v-model="filterForm.createdAtFrom"
                    reset-button
                    close-button
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <b-form-datepicker
                    :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                    :placeholder="$t(filterFormFields.createdAtTo.name)"
                    v-model="filterForm.createdAtTo"
                    reset-button
                    close-button
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <b-form-datepicker
                    :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                    :placeholder="$t(filterFormFields.changedAtFrom.name)"
                    v-model="filterForm.changedAtFrom"
                    reset-button
                    close-button
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <b-form-datepicker
                    :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                    :placeholder="$t(filterFormFields.changedAtTo.name)"
                    v-model="filterForm.changedAtTo"
                    reset-button
                    close-button
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <b-form-datepicker
                    :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                    :placeholder="$t(filterFormFields.orderedAtFrom.name)"
                    v-model="filterForm.orderedAtFrom"
                    reset-button
                    close-button
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <b-form-datepicker
                    :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric' }"
                    :placeholder="$t(filterFormFields.orderedAtTo.name)"
                    v-model="filterForm.orderedAtTo"
                    reset-button
                    close-button
                ></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <b-form-tags input-id="trackings-tags" v-model="filterForm.trackings" separator=" ,;" :placeholder="$t(filterFormFields.tracking.name)" add-on-change></b-form-tags>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <b-form-input v-model.trim="filterForm.createdBy" :placeholder="$t(filterFormFields.createdBy.name)"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <b-form-input v-model.trim="filterForm.email" :placeholder="$t(filterFormFields.email.name)"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <b-dropdown block :text="$t(filterFormFields.statuses.name)" :variant="filterForm.statuses.length > 0 ? 'success' : 'outline-secondary'">
                  <b-form-checkbox-group
                      class="px-2"
                      v-model="filterForm.statuses"
                      :options="rmaStatuses"
                      name="statuses[]"
                      stacked
                  ></b-form-checkbox-group>
                </b-dropdown>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <b-dropdown block :text="$t(filterFormFields.reasons.name)" :variant="filterForm.reasons.length > 0 ? 'success' : 'outline-secondary'">
                  <b-form-checkbox-group
                      class="px-2"
                      v-model="filterForm.reasons"
                      :options="returnReasons"
                      name="reasons[]"
                      stacked
                  ></b-form-checkbox-group>
                </b-dropdown>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <b-dropdown block :text="$t(filterFormFields.resolutions.name)" :variant="filterForm.resolutions.length > 0 ? 'success' : 'outline-secondary'">
                  <b-form-checkbox-group
                      class="px-2"
                      v-model="filterForm.resolutions"
                      :options="resolutions"
                      name="resolutions[]"
                      stacked
                  ></b-form-checkbox-group>
                </b-dropdown>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <b-dropdown block :text="$t(filterFormFields.operations.name)" :variant="filterForm.operations.length > 0 ? 'success' : 'outline-secondary'">
                  <b-form-checkbox-group
                      class="px-2"
                      v-model="filterForm.operations"
                      :options="operations"
                      name="operations[]"
                      stacked
                  ></b-form-checkbox-group>
                </b-dropdown>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <b-dropdown block :text="$t(filterFormFields.sites.name)" :variant="filterForm.sites.length > 0 ? 'success' : 'outline-secondary'">
                  <b-form-checkbox-group
                      class="px-2"
                      v-model="filterForm.sites"
                      :options="dictionaries.brands"
                      name="sites[]"
                      stacked
                  ></b-form-checkbox-group>
                </b-dropdown>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <b-dropdown block :text="$t(filterFormFields.regions.name)" :variant="filterForm.regions.length > 0 ? 'success' : 'outline-secondary'">
                  <b-form-checkbox-group
                      class="px-2"
                      v-model="filterForm.regions"
                      :options="dictionaries.regions"
                      name="regions[]"
                      stacked
                  ></b-form-checkbox-group>
                </b-dropdown>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <MultiselectFilterDropdown
                  :text="$t(filterFormFields.shippingCountries.name)"
                  :variant="filterForm.shippingCountries.length > 0 ? 'success' : 'outline-secondary'"
                  v-model="filterForm.shippingCountries"
                  :options="shippingCountriesOptions"
                  input-name="shippingCountries[]"
                ></MultiselectFilterDropdown>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <b-dropdown block :text="$t(filterFormFields.paymentMethods.name)" :variant="filterForm.paymentMethods.length > 0 ? 'success' : 'outline-secondary'">
                  <b-form-checkbox-group
                      class="px-2"
                      v-model="filterForm.paymentMethods"
                      :options="paymentMethods"
                      name="statuses[]"
                      stacked
                  ></b-form-checkbox-group>
                </b-dropdown>
              </b-form-group>
            </b-col>
            <b-col offset="3" cols="6" class="mt-3">
              <b-button block variant="outline-success" type="submit" style="margin-left: auto;">{{ $t('Filter') }}</b-button>
            </b-col>
          </b-form-row>
        </b-container>
      </b-form>
    </b-sidebar>
    <div><h1>{{ $t('Returns') }}</h1></div>
    <b-card body-class="py-2">
      <b-button v-b-toggle.sidebar-filter variant="info" class="mr-3"><b-icon icon="list"></b-icon> {{ $t('Filter') }}</b-button>
      <b-form-tag
          v-for="(val, index) in queryFilterParsed"
          @remove="deleteFromFilter(val)"
          :key="index"
          variant="Light"
      >
        {{ val.value }}
      </b-form-tag>
    </b-card>
    <b-table
        :api-url="getApiUrl"
        hover
        responsive
        :busy.sync="loadingReturns"
        :items="myProvider"
        :fields="fields"
        :tbody-tr-class="rowClass"
    >
      <template #head()="data">{{ $t(data.label) }}</template>
      <template #cell(rmaNumber)="data">
        <b-link :to="{ name: 'rma', params: { rmaNumber: data.item.rmaNumber } }">{{ data.item.rmaNumber }}</b-link>
      </template>
      <template #cell(action)="data">
        <b-link :to="{ name: 'rma', params: { rmaNumber: data.item.rmaNumber } }">{{ $t('Edit') }}</b-link>
      </template>
      <template #cell(createdAt)="data">
        <span>{{ dateTimeFormat(data.item.createdAt) }}</span>
      </template>
      <template #cell(orderNumber)="data">
        <b-link target="_blank" :to="{ name: 'returns', query: { filter: {orderNumber: data.item.orderNumber} } }">{{ data.item.orderNumber }}</b-link>
      </template>
      <template #cell(paymentMethod)="data">
        {{ $t(`payment_methods.${data.item.paymentMethod}`) }}
      </template>
      <template #cell(resolutions)="data">
        {{ data.item.resolutions.map(val => $t(`resolutions.${val}`)) }}
      </template>
      <template #cell(statuses)="data">
        {{ data.item.statuses.map(val => $t(`rma_statuses.${val}`)) }}
      </template>
    </b-table>
    <b-navbar v-if="totalRows > 0" type="dark">
      <b-navbar-nav style="margin-left: auto;">
        <span class="pr-3">{{ $t('Shown') }} {{ shown }} {{ $t('from') }} {{ totalRows }}</span>
      </b-navbar-nav>
      <b-navbar-nav>
        <b-pagination
            v-model.number="queryPage"
            :total-rows="totalRows"
            :per-page="queryPerPage"
        ></b-pagination>
      </b-navbar-nav>
      <b-navbar-nav>
        <b-form-select
            v-model.number="queryPerPage"
            :options="listPerPage"
        ></b-form-select>
      </b-navbar-nav>
    </b-navbar>
  </b-container>
</template>

<script>
import {getJsonData} from "../services/fetchApi";
import {dateTimeFormat} from "../services/DateTimeFormat";
import MultiselectFilterDropdown from "./helpers/MultiselectFilterDropdown"
import qs from "qs";
import {ReturnReason as ReturnReasonEnum} from "../enum/ReturnReason";
import {Resolution as ResolutionEnum} from "../enum/Resolution";
import {Operation as OperationEnum} from "../enum/Operation";
import {RmaStatus as RmaStatusEnum} from "../enum/RmaStatus";
import {PaymentMethod as PaymentMethodEnum} from "../enum/PaymentMethod";

const initPage = 1;
const initPerPage = 10;
export default {
  data() {
    return {
      loadingReturns: false,
      totalRows: 0,
      shown: 0,
      listPerPage: [5, 10, 20, 50, 100],
      shippingCountriesFilter: '',
      filterFormFields: {
        rmaNumber: {
          name: 'RMA Number',
          defaultValue: null
        },
        rmaNumbers: {
          name: 'RMA Number',
          defaultValue: []
        },
        orderNumber: {
          name: 'Order Number',
          defaultValue: null
        },
        orderNumbers: {
          name: 'Order Number',
          defaultValue: []
        },
        createdAtFrom: {
          name: 'Created RMA at from',
          defaultValue: null
        },
        createdAtTo: {
          name: 'Created RMA at to',
          defaultValue: null
        },
        changedAtFrom: {
          name: 'Last update RMA from',
          defaultValue: null
        },
        changedAtTo: {
          name: 'Last update RMA to',
          defaultValue: null
        },
        orderedAtFrom: {
          name: 'Ordered at from',
          defaultValue: null
        },
        orderedAtTo: {
          name: 'Ordered at to',
          defaultValue: null
        },
        tracking: {
          name: 'Tracking Number',
          defaultValue: null
        },
        trackings: {
          name: 'Tracking Number',
          defaultValue: []
        },
        createdBy: {
          name: 'Created RMA by',
          defaultValue: null
        },
        email: {
          name: 'Email',
          defaultValue: null
        },
        statuses: {
          name: 'Statuses',
          defaultValue: []
        },
        reasons: {
          name: 'Reasons',
          defaultValue: []
        },
        resolutions: {
          name: 'Resolutions',
          defaultValue: []
        },
        operations: {
          name: 'Operations',
          defaultValue: []
        },
        sites: {
          name: 'Sites',
          defaultValue: []
        },
        regions: {
          name: 'Regions',
          defaultValue: []
        },
        shippingCountries: {
          name: 'Shipping Countries',
          defaultValue: []
        },
        paymentMethods: {
          name: 'Payment Methods',
          defaultValue: []
        },
      },
      filterForm: {},
      fields: [
        {
          key: 'rmaNumber',
          label: 'RMA Number',
          tdClass: 'text-nowrap'
        },
        {
          key: 'createdAt',
          label: 'Date',
          tdClass: 'text-nowrap'
        },
        {
          key: 'createdBy',
          label: 'Created By',
          tdClass: 'text-nowrap'
        },
        {
          key: 'orderNumber',
          label: 'Order Number',
        },
        {
          key: 'fullName',
          label: 'Full Name',
        },
        {
          key: 'paymentMethod',
          label: 'Payment Method',
        },
        {
          key: 'resolutions',
          label: 'Resolutions',
        },
        {
          key: 'statuses',
          label: 'Statuses',
        },
        {
          key: 'action',
          label: '',
        },
      ]
    }
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.statuses.filter(item => item === 'REQUESTED').length > 0) return 'table-success'
    },
    dateTimeFormat(datetime) {
      return dateTimeFormat(datetime);
    },
    myProvider(ctx) {
      const $this = this;

      this.loadingReturns = true;
      $this.$store.commit('setLoadingAnything', true);

      return getJsonData(ctx.apiUrl)
          .then(json => {
            $this.totalRows = json.pagination.totalRows;
            $this.shown = json.returns.length;
            return json.returns;
          }).catch(error => {
            $this.$store.commit('showToast', {
              message: error,
              title: $this.$t('Can not load list of RMA'),
              type: 'danger'
            });
            return [];
          }).finally(() => {
            this.loadingReturns = false;
            $this.$store.commit('setLoadingAnything', false)
          })
    },
    initFilterForm() {
      const $this = this;
      const qf = $this.queryFilter;
      Object.keys(this.filterFormFields).forEach(key => {
        $this.filterForm[key] = (qf && key in qf && qf[key]) ? qf[key] : this.filterFormFields[key].defaultValue;
      });
    },
    setFilter() {
      let filter = JSON.parse(JSON.stringify(this.filterForm));
      Object.keys(this.filterFormFields).forEach(key => {
        if (!filter[key]) {
          delete filter[key];
        }
      });
      this.queryFilter = filter;
    },
    deleteFromFilter(val) {
      if (Array.isArray(this.filterForm[val.key])) {
        this.$set(this.filterForm, val.key, this.filterForm[val.key].filter((item, key) => key !== val.index));
      } else {
        this.filterForm[val.key] = null;
      }
      this.setFilter();
    }
  },
  computed: {
    getActionUrl: function() {
      return {
        getReturns: process.env.VUE_APP_BACKEND_SERVER + 'api/returns'
      }
    },
    dictionaries: function () {
      if (!this.$store.state.dictionaries) {
        return [];
      }
      return this.$store.state.dictionaries;
    },
    getApiUrl() {
      return this.getActionUrl.getReturns + '?' + qs.stringify(this.$route.query);
    },
    queryFilter: {
      get: function () {
        return 'filter' in this.$route.query ? this.$route.query.filter : null;
      },
      set: function (filter) {
        this.$router
            .push({
              query: {
                ...this.$route.query,
                filter: filter
              }
            })
            .catch();
      }
    },
    queryPage: {
      get() {
        return this.$route.query.page ?? initPage;
      },
      set(val) {
        if (val === this.queryPage) {
          return;
        }
        this.$router
            .push({
              query: {
                ...this.$route.query,
                page: val
              }
            })
            .catch();
      }
    },
    queryPerPage: {
      get() {
        return this.$route.query.perPage ?? initPerPage;
      },
      set(val) {
        if (val === this.queryPerPage) {
          return;
        }
        this.$router
            .push({
              query: {
                ...this.$route.query,
                perPage: val
              }
            })
            .catch();
      }
    },
    queryFilterParsed() {
      let usedFilters = [];
      if (!this.queryFilter) {
        return usedFilters;
      }
      Object.keys(this.queryFilter).forEach(filter => {
        if (Array.isArray(this.queryFilter[filter])) {
          this.queryFilter[filter].forEach((val, index) => {
            usedFilters.push({
              key: filter,
              index: index,
              value: val
            })
          })
        } else {
          usedFilters.push({
            key: filter,
            index: null,
            value: this.queryFilter[filter]
          });
        }
      })

      return usedFilters;
    },
    shippingCountriesOptions() {
      if (!this.dictionaries || !this.dictionaries.countries) {
        return [];
      }
      return this.dictionaries.countries.filter(item => item.text.toLowerCase().includes(this.shippingCountriesFilter.toLowerCase()))
    },
    returnReasons() {
      const $this = this;
      return Object.keys(ReturnReasonEnum).map(key => ({value: key, text: $this.$t(`return_reasons.${key}`)}));
    },
    resolutions() {
      const $this = this;
      return Object.keys(ResolutionEnum).map(key => ({value: key, text: $this.$t(`resolutions.${key}`)}));
    },
    operations() {
      const $this = this;
      return Object.keys(OperationEnum).map(key => ({value: key, text: $this.$t(`operations.${key}`)}));
    },
    rmaStatuses() {
      const $this = this;
      return Object.keys(RmaStatusEnum).map(key => ({value: key, text: $this.$t(`rma_statuses.${key}`)}));
    },
    paymentMethods() {
      const $this = this;
      return Object.keys(PaymentMethodEnum).map(key => ({value: key, text: $this.$t(`payment_methods.${key}`)}));
    }
  },
  created() {
    this.initFilterForm();
  },
  watch:{
    queryFilter() {
      this.initFilterForm()
    }
  },
  components: {
    MultiselectFilterDropdown
  }
}
</script>