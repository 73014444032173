<template>
  <div>
    <div v-if="getRmaHistoryLoading"><b-spinner small class="spinner-border mx-2" label="Small Spinner"></b-spinner></div>
    <div v-else>
      <b-table-simple responsive small v-if="getHistory && getHistory.length > 0">
        <b-thead>
          <b-tr>
            <b-th>{{ $t('Date/Time') }}</b-th>
            <b-th>{{ $t('User') }}</b-th>
            <b-th>{{ $t('Product') }}</b-th>
            <b-th>{{ $t('Field') }}</b-th>
            <b-th>{{ $t('Old Value') }}</b-th>
            <b-th>{{ $t('New Value') }}</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="item in getHistory" :key="item.id">
            <b-td>{{ dateTimeFormat(item.createdAt) }}</b-td>
            <b-td>{{ item.createdBy }}</b-td>
            <b-td>#{{ item.productNumber }} {{ item.productSku }}</b-td>
            <b-td>{{ item.field }}</b-td>
            <b-td>{{ item.oldValue }}</b-td>
            <b-td>{{ item.newValue }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <div v-else>
        <b-alert show="show">{{ $t('No History') }}</b-alert>
      </div>
    </div>
  </div>
</template>
<script>
import {dateTimeFormat} from "../services/DateTimeFormat";

export default {
  created() {
    this.$store.dispatch('getRmaHistory');
  },
  methods: {
    dateTimeFormat: function (datetime) {
      return dateTimeFormat(datetime);
    },
  },
  computed: {
    getHistory: function () {
      return this.$store.getters.getRmaHistory;
    },
    getRmaHistoryLoading: function () {
      return this.$store.getters.getRmaHistoryLoading;
    }
  }
}
</script>