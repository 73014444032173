<template>
  <b-container fluid="">
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-list-group horizontal="" class="order-info">
          <b-list-group-item class="flex-grow-1">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="30%"></b-skeleton>
          </b-list-group-item>
          <b-list-group-item class="flex-grow-1">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="30%"></b-skeleton>
          </b-list-group-item>
          <b-list-group-item class="flex-grow-1">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="30%"></b-skeleton>
          </b-list-group-item>
          <b-list-group-item class="flex-grow-1">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="30%"></b-skeleton>
          </b-list-group-item>
        </b-list-group>
        <br class="p-5">
        <b-card>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </template>
      <div v-if="!loading">
        <h1>{{ $t('Order Info') }}: #{{ orderInfo.orderNumber }}</h1>
        <b-list-group horizontal="" class="order-info">
          <b-list-group-item>
            <h4>{{ $t('Order Information') }}</h4>
            <hr class="mt-0">
            <p>{{ dateTimeFormat(orderInfo.orderedDate) }}</p>
            <p><b-link target="_blank" :href="'https://dashboard.plein-lab.com/crm/orders?query_orderid=' + orderInfo.orderNumber">{{ orderInfo.orderNumber }}</b-link></p>
            <b-link :to="{ name: 'returnOrder', params: { id: orderInfo.id }}">
              <b-button variant="info text-uppercase">{{ $t('Process Return') }}</b-button>
            </b-link>
          </b-list-group-item>
          <b-list-group-item>
            <h4>{{ $t('Billing Address') }}</h4>
            <hr class="mt-0">
            <p>{{ orderInfo.billingAddress.fullName }}</p>
            <p>{{ orderInfo.billingAddress.billingAddress1 }}</p>
            <p>{{ orderInfo.billingAddress.billingAddress2 }}</p>
            <p>{{ orderInfo.billingAddress.city }}, {{ orderInfo.billingAddress.postalCode }}</p>
            <p>{{ orderInfo.billingAddress.countryCode }}</p>
            <p>{{ orderInfo.billingAddress.phone }}</p>
          </b-list-group-item>
          <b-list-group-item>
            <h4>{{ $t('Payment Method') }}</h4>
            <hr class="mt-0">
            <div v-for="method in orderInfo.paymentInfo.methods" :key="method.paymentId">
              <p><b>{{ $t(`payment_methods.${method.method}`) }}</b></p>
              <p>{{ method.holder }}</p>
              <p>{{ method.maskedNumber }}</p>
              <p>{{ orderInfo.currency }} {{ method.amount }}</p>
            </div>
            <template v-if="usedStoreCredit">
              <p class="mt-3"><b>{{ $t('Store Credit') }}</b></p>
              <p>{{ usedStoreCredit.coupon }}</p>
              <p>{{ orderInfo.currency }} {{ Math.abs(usedStoreCredit.order.reduce((sum, promotion) => (sum + promotion.price), 0)).toFixed(2) }}</p>
            </template>
          </b-list-group-item>
          <b-list-group-item>
            <h4>{{ $t('Order Total') }}</h4>
            <hr class="mt-0">
            <p><b>{{ $t('Items Amount') }}:</b> {{ orderInfo.currency }} {{ orderInfo.paymentInfo.itemsAmount.toFixed(2) }}</p>
            <p><b>{{ $t('Shipping') }}:</b> {{ orderInfo.currency }} {{ orderInfo.paymentInfo.totalShippingPrice.toFixed(2) }}</p>
            <template v-if="orderInfo.paymentInfo.isCod">
              <p class="pl-3">{{ $t('Price') }}: {{ orderInfo.currency }} {{ orderInfo.paymentInfo.shippingPrice.toFixed(2) }}</p>
              <p class="pl-3">{{ $t('COD Fee') }}: {{ orderInfo.currency }} {{ orderInfo.paymentInfo.codFee.toFixed(2) }}</p>
            </template>
            <p v-if="orderInfo.region === '02'"><b>{{ $t('Sales Tax') }}:</b> {{ orderInfo.currency }} {{ orderInfo.paymentInfo.salesTax.toFixed(2) }}</p>
            <template v-if="orderInfo && orderInfo.coupon && orderInfo.coupon.order">
              <p><b>{{ $t('Coupon') }}:</b> {{ orderInfo.currency }} {{ orderInfo.coupon.order.price }}</p>
            </template>
            <p class="mb-0"><b>{{ $t('Subtotal') }}:</b> {{ orderInfo.currency }} {{ orderInfo.paymentInfo.subTotalAmount.toFixed(2) }}</p>
            <p class="mb-0"><b>{{ $t('Total Amount') }}:</b> {{ orderInfo.currency }} {{ orderInfo.paymentInfo.totalPaidAmount.toFixed(2) }}</p>
          </b-list-group-item>
        </b-list-group>
        <h4 class="mt-5">{{ $t('Order Items') }}</h4>
        <b-table-simple>
          <b-tr>
            <b-th colspan="2">{{ $t('Item') }}</b-th>
            <b-th>{{ $t('Qty Ordered') }}</b-th>
            <b-th>{{ $t('Qty Shipped') }}</b-th>
            <b-th>{{ $t('Qty Returned') }}</b-th>
            <b-th>{{ $t('Price') }}</b-th>
            <b-th>{{ $t('Shipping Status') }}</b-th>
          </b-tr>
          <b-tbody v-for="(shipment, index) in this.orderInfo.shipments" :key="shipment.id" :index="index">
            <b-tr>
              <b-td colspan="7"><b>{{ $t('Shipment') }} #{{index + 1}}</b>: {{ shipment.address.fullName }}, {{ shipment.address.address1 }}, {{ shipment.address.address2 }}, {{ shipment.address.city }},
                {{ shipment.address.stateCode }} {{ shipment.address.postalCode }}, {{ shipment.address.countryCode }}, {{ shipment.address.phone }}</b-td>
            </b-tr>
            <b-tr v-for="product in shipment.products" :key="product.id">
              <b-td style="width: 150px">
                <b-img class="w-100 px-3" :src="product.pictureUrl"></b-img>
              </b-td>
              <b-td>
                <ul class="list-unstyled">
                  <li><b>{{ product.name }}</b></li>
                  <li>{{ $t('SKU') }}: {{ product.sku }}</li>
                  <li>{{ $t('Color') }}: {{ product.color.name }}</li>
                  <li>{{ $t('Size') }}: {{ product.size }}</li>
                </ul>
              </b-td>
              <b-td>{{ product.quantityOrdered }}</b-td>
              <b-td>{{ product.quantityShipped }}</b-td>
              <b-td>{{ product.quantityReturned }}</b-td>
              <b-td>
                <div class="text-nowrap">{{ orderInfo.currency }}&nbsp;{{ product.finalPrice.toFixed(2) }}</div>
              </b-td>
              <b-td>
                <ul class="list-unstyled">
                  <li>{{ $t('Method') }}: {{ $t(`shipping_methods.${shipment.shippingMethod}`) }}</li>
                  <li>{{ $t('Status') }}: {{ $t(`shipping_statuses.${shipment.shippingStatus}`) }}</li>
                </ul>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </b-skeleton-wrapper>
  </b-container>
</template>

<script>
import {getJsonData} from "../services/fetchApi";
import { dateTimeFormat } from "../services/DateTimeFormat";

export default {
  data() {
    return {
      loading: true,
      orderInfo: null
    }
  },
  created() {
    this.retrieveOrder(this.orderId);
  },
  methods: {
    dateTimeFormat: function (datetime) {
      return dateTimeFormat(datetime);
    },
    retrieveOrder: function (orderId) {
      const $this = this;
      $this.$set(this, 'loading', true);
      $this.$set(this, 'orderInfo', null);
      getJsonData(process.env.VUE_APP_BACKEND_SERVER + 'api/orderInfo/' + orderId)
          .then(json => {
            $this.$set(this, 'loading', false);
            $this.$set(this, 'orderInfo', json.order);
          })
          .catch(error => {
            $this.$store.commit('showToast', {
              message: $this.$t('Can not find the order') + ': ' + (error ?? $this.$t('Unknown error')),
              title: $this.$t('Loading Order'),
              type: 'danger'
            });
          });
    }
  },
  computed: {
    orderId() {
      return this.$route.params.id;
    },
    usedStoreCredit() {
      return this.orderInfo.paymentInfo.usedStoreCredit;
    }
  },
  watch:{
    orderId(id) {
      this.retrieveOrder(id);
    }
  }
}
</script>

<style scoped>
.order-info .list-group-item{
  flex-grow: 1;
}
.order-info p {
  margin-bottom: 0.2em;
}
</style>
