<template>
  <b-overlay :show="loadingSizes">
    <b-select :options="sizes" v-model="model" required></b-select>
    <b-alert variant="danger" :show="!hasSizesForExchange">{{ $t('Sorry, we don\'t have in stock available sizes for exchange') }}</b-alert>
  </b-overlay>
</template>

<script>
import {postJsonData} from "../services/fetchApi";

export default {
  name: "OrderProductSizesForExchange",
  props: ['value', 'sku', 'size', 'region', 'country'],
  data() {
    return {
      model: null,
      sizes: null,
      loadingSizes: false
    }
  },
  mounted() {
    this.$set(this, 'model', this.value);
    this.getAvailableSizesForExchange();
  },
  methods: {
    getAvailableSizesForExchange() {
      const $this = this;
      $this.loadingSizes = true;
      postJsonData(process.env.VUE_APP_BACKEND_SERVER + 'get_available_sizes_for_exchange', JSON.stringify({
        sku: $this.sku,
        region: $this.region,
        country: $this.country
      }))
      .then(json => {
        $this.sizes = json;
      })
      .catch(error => {
        if (process.env.NODE_ENV === 'development') {
          $this.$store.commit('showToast', {
            message: error,
            title: 'Can not get available sizes',
            type: 'danger'
          }, {root: true});
        }
      }).finally(() => {
        $this.loadingSizes = false;
      });
    },
  },
  computed: {
    hasSizesForExchange: function () {
      return this.sizes === null || this.sizes.length > 0;
    }
  },
  watch: {
    value() {
      this.$set(this, 'model', this.value);
    },
    model() {
      this.$emit('input', this.model);
    },
  }
}
</script>