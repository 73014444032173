<template>
  <b-overlay :show="creatingUser || updatingUser">
    <b-form method="POST" :action="getActionUrl.createUser" @submit.prevent="createUser($event)">
      <b-table
          id="users_list"
          hover
          responsive
          :items="modelUserList"
          :fields="fields"
          foot-clone
          :per-page="perPage"
          :current-page="currentPage"
      >
        <template #head()="data">{{ $t(data.label) }}</template>
        <template #cell(createdAt)="data">
          <span>{{ dateTimeFormat(data.item.createdAt) }}</span>
        </template>
        <template #cell(roles)="data">
          <b-form-select
              :options="dictionaries.userRoles"
              v-model="editUserModel.roles"
              name="role"
              v-if="editUserId === data.item.id"
              multiple
          ></b-form-select>
          <span v-else>{{ data.item.roles }}</span>
        </template>
        <template #cell(isActive)="data">
          <b-form-checkbox v-model="editUserModel.isActive" size="lg" v-if="editUserId === data.item.id"></b-form-checkbox>
          <span v-else>{{ data.item.isActive }}</span>
        </template>
        <template #cell(action)="data">
          <b-button v-if="editUserId === data.item.id" class="mr-3" @click="updateUser(data.item.id)" variant="success">{{ $t('Update') }}</b-button>
          <b-button v-if="editUserId === data.item.id" class="mr-3" @click="cancelEdit()" variant="danger">{{ $t('Cancel') }}</b-button>
          <b-button v-if="!(editUserId === data.item.id)" @click="editUser(data.item.id, data.item.roles, data.item.isActive)" variant="info">{{ $t('Edit') }}</b-button>
        </template>

        <template v-if="addNewUser" #foot(email)="">
          <b-form-input autocomplete="false" v-model.trim="newUser.email" :placeholder="$t('Email')" name="email" required></b-form-input>
          <b-input-group>
            <b-form-input autocomplete="false" v-model.trim="newUser.password" :placeholder="$t('Password')" name="password" required></b-form-input>
            <b-input-group-append>
              <b-button variant="outline-info" @click="generatePassword">{{ $t('Generate') }}</b-button>
            </b-input-group-append>
          </b-input-group>
        </template>
        <template v-if="addNewUser" #foot(name)="">
          <b-form-input autocomplete="false" v-model.trim="newUser.name" :placeholder="$t('Name')" name="name" required></b-form-input>
        </template>
        <template v-if="addNewUser" #foot(createdAt)="">-</template>
        <template v-if="addNewUser" #foot(roles)="">
          <b-form-select
            :options="dictionaries.userRoles"
            v-model="newUser.roles"
            name="role"
            required
            multiple
          ></b-form-select>
        </template>
        <template v-if="addNewUser" #foot(isActive)="">
          <b-form-checkbox v-model="newUser.isActive" size="lg"></b-form-checkbox>
        </template>
        <template #foot(action)="">
          <b-button v-if="addNewUser" class="mr-3" type="submit" variant="success">{{ $t('Create') }}</b-button>
          <b-button :variant="addNewUser ? 'danger': 'info'" @click="addNewUser = !addNewUser">{{ $t(addNewUser ? 'Cancel' : 'Add') }}</b-button>
        </template>
      </b-table>
    </b-form>
    <b-container fluid>
      <b-pagination
          v-model="currentPage"
          :total-rows="modelUserList.length"
          :per-page="perPage"
          aria-controls="users_list"
          align="right"
      ></b-pagination>
    </b-container>
  </b-overlay>
</template>

<script>
import {getJsonData, postData} from "../services/fetchApi";
import {dateTimeFormat} from "../services/DateTimeFormat";

const string = "abcdefghijklmnopqrstuvwxyz";
const numeric = "0123456789";
const punctuation = "!@#$%^&*()_+~`|}{[]:;?><,./-=";

export default {
  name: "UsersPage",
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      userLoading: false,
      creatingUser: false,
      updatingUser: false,
      addNewUser: false,
      editUserId: null,
      editUserModel: {
        roles: [],
        isActive: false
      },
      newUser: {
        password: null,
        name: null,
        email: null,
        roles: [],
        isActive: false
      },
      modelUserList: [],
      fields: [
        {
          key: 'email',
          label: 'Email',
          tdClass: 'text-nowrap'
        },
        {
          key: 'name',
          label: 'Name',
          tdClass: 'text-nowrap'
        },
        {
          key: 'roles',
          label: 'Roles',
          tdClass: 'text-nowrap'
        },
        {
          key: 'isActive',
          label: 'Activated'
        },
        {
          key: 'createdAt',
          label: 'Date From',
          tdClass: 'text-nowrap'
        },
        {
          key: 'action',
          label: 'Action',
          tdClass: 'col-action'
        },
      ]
    }
  },
  mounted() {
    this.retrieveUsers();
  },
  methods: {
    retrieveUsers() {
      const $this = this;
      $this.userLoading = true;
      $this.$store.commit('setLoadingAnything', true);
      getJsonData(this.getActionUrl.getUsers)
          .then(json => {
            $this.modelUserList = json.users;
          })
          .catch(error => {
            $this.$store.commit('showToast', {
              message: $this.$t('Can not load list of users') + ': ' + (error ?? $this.$t('Unknown error')),
              title: $this.$t('Loading Users'),
              type: 'danger'
            });
          })
          .finally(() => {
            $this.userLoading = true;
            $this.$store.commit('setLoadingAnything', false);
          });
    },
    dateTimeFormat(datetime) {
      return dateTimeFormat(datetime);
    },
    createUser(e) {
      const $this = this;
      $this.creatingUser = true;
      postData(e.target.action, JSON.stringify($this.newUser))
          .then(() => {
            Object.keys($this.newUser).forEach(field => {
              $this.newUser[field] = null;
            });

            $this.retrieveUsers();
            $this.$store.commit('showToast', {
              message: $this.$t('New user has been created'),
              title: $this.$t('Success'),
              type: 'success'
            });
          })
          .catch(error => {
            $this.$store.commit('showToast', {
              message: $this.$t('Can not create a new user') + ': ' + (error ?? $this.$t('Unknown error')),
              title: $this.$t('Error'),
              type: 'danger'
            });
          })
          .finally(() => {
            $this.creatingUser = false;
          });
    },
    updateUser(userId) {
      const $this = this;
      $this.updatingUser = true;
      postData($this.getActionUrl.updateUser + userId, JSON.stringify($this.editUserModel))
          .then(() => {
            $this.cancelEdit();
            $this.retrieveUsers();

            $this.$store.commit('showToast', {
              message: $this.$t('The user has been updated'),
              title: $this.$t('Success'),
              type: 'success'
            });
          })
          .catch(error => {
            $this.$store.commit('showToast', {
              message: $this.$t('Can not update the new user') + ': ' + (error ?? $this.$t('Unknown error')),
              title: $this.$t('Error'),
              type: 'danger'
            });
          })
          .finally(() => {
            $this.updatingUser = false;
          });
    },
    cancelEdit() {
      this.editUserId = null;
      this.editUserModel = {
        roles: null,
        isActive: false
      }
    },
    editUser(id, roles, isActive) {
      this.editUserId = id;
      this.editUserModel = {
        roles: Array.isArray(roles) && roles.length > 0 ? roles : [],
        isActive: isActive
      }
    },
    generatePassword() {
      const length = 12;
      let character = "";
      let password = "";
      while (password.length < length) {
        const entity1 = Math.ceil(
            string.length * Math.random() * Math.random()
        );
        const entity2 = Math.ceil(
            numeric.length * Math.random() * Math.random()
        );
        const entity3 = Math.ceil(
            punctuation.length * Math.random() * Math.random()
        );
        let hold = string.charAt(entity1);
        hold = password.length % 2 === 0 ? hold.toUpperCase() : hold;
        character += hold;
        character += numeric.charAt(entity2);
        character += punctuation.charAt(entity3);
        password = character;
      }
      password = password
          .split("")
          .sort(() => {
            return 0.5 - Math.random();
          })
          .join("");
      this.newUser.password = password.substr(0, length);
    },
  },
  computed: {
    getActionUrl: function() {
      return {
        getUsers: process.env.VUE_APP_BACKEND_SERVER + 'api/users',
        createUser: process.env.VUE_APP_BACKEND_SERVER + 'api/users',
        updateUser: process.env.VUE_APP_BACKEND_SERVER + 'api/users/',
      }
    },
    dictionaries: function () {
      if (!this.$store.state.dictionaries) {
        return [];
      }
      return this.$store.state.dictionaries;
    },
  }
}
</script>

<style scoped>
  .col-action {
    width: 200px;
  }
</style>