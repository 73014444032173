<template>
  <b-button
      :variant="!images || images.length === 0 ? 'outline-secondary' : 'outline-danger'"
      @click="viewer(images)"
      :disabled="!images || images.length === 0"
  >
    <b-icon icon="camera"></b-icon>
  </b-button>
</template>

<script>
import { api as viewerApi } from "v-viewer"

export default {
  name: "PhotoViewerButton",
  props: {
    images: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    }
  },
  methods: {
    uploadedFileUrl(path) {
      if (path.indexOf('/Impex') === 0) {
        return 'https://production-web-plein.demandware.net/on/demandware.servlet/webdav/Sites' + path;
      }
      return process.env.VUE_APP_BACKEND_SERVER + path;
    },
    viewer(images) {
      const $this = this;
      viewerApi({
        images: images.map(photo => $this.uploadedFileUrl(photo)),
        options: {
          zoomRatio: 0.7
        }
      })
    }
  }
}
</script>

<style scoped>

</style>