import {postJsonData} from "../services/fetchApi";

export default {
    data() {
        return {
            sendingFindOrder: false,
            error: "",
            form: {
                sourceOrderId: null,
                sourceSlug: null
            }
        }
    },
    created() {
        this.form.sourceSlug = 'src' in this.$route.query ? this.$route.query.src : '';
    },
    methods: {
        findOrder(e) {
            const $this = this;
            $this.sendingFindOrder = true;
            $this.$store.commit('setLoadingAnything', true);
            $this.error = "";
            $this.form.sourceOrderId = $this.form.sourceOrderId.trim();
            postJsonData(
                e.target.action,
                JSON.stringify($this.form)
            )
                .then(json => {
                    $this.form.sourceOrderId = null;
                    $this.$router.push({ name: 'orderInfo', params: { id: json.id } });
                })
                .catch(error => {
                    $this.error = error;
                    $this.$store.commit('showToast', {
                        message: error,
                        title: 'Error',
                        type: 'danger'
                    });
                })
                .finally(() => {
                    $this.sendingFindOrder = false;
                    $this.$store.commit('setLoadingAnything', false);
                });
            return false;
        }
    },
    computed: {
        getActionUrl: function() {
            return {
                findOrder: process.env.VUE_APP_BACKEND_SERVER + 'api/bo/find-order'
            }
        }
    }
};