import Home from "../components/Home";
import OrderFind from "../components/OrderFind";
import OrderInfo from "../components/OrderInfo";
import ReturnOrder from "../components/ReturnOrder";
import Returns from "../components/Returns";
import Rma from "../components/Rma";
import Auth from "../components/Auth";
import UsersPage from "../components/UsersPage";

const routes = [
    {
        path: "/",
        component: Home,
        name: 'home',
        meta: {
            title: () => 'Home',
            roles: ['ROLE_USER']
        }
    },
    {
        path: "/find-order",
        component: OrderFind,
        name: 'findOrder',
        meta: {
            title: () => 'Find Order',
            roles: ['ROLE_CAN_CREATE_RETURN']
        }
    },
    {
        path: "/find-order/:id",
        name:'orderInfo',
        component: OrderInfo,
        meta: {
            title: () => 'Order Info',
            roles: ['ROLE_CAN_CREATE_RETURN']
        }
    },
    {
        path: "/return-order/:id",
        name:'returnOrder',
        component: ReturnOrder,
        meta: {
            title: () => 'Return Order',
            roles: ['ROLE_CAN_CREATE_RETURN']
        }
    },
    {
        path: "/returns",
        name:'returns',
        component: Returns,
        meta: {
            title: () => 'Returns',
            roles: ['ROLE_USER']
        }
    },
    {
        path: "/returns/:rmaNumber",
        name:'rma',
        component: Rma,
        meta: {
            title: to => 'RMA ' + (to.params.rmaNumber ?? ''),
            roles: ['ROLE_USER']
        }
    },
    {
        path: "/users/",
        name: 'users',
        component: UsersPage,
        meta: {
            title: () => 'Users',
            roles: ['ROLE_ADMIN']
        }
    },
    {
        path: "/login",
        name:'login',
        component: Auth,
        meta: {
            title: () => 'Login'
        }
    },
];

export default routes;