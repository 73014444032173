<template>
  <b-container>
    <b-row>
      <b-col cols="6" offset="3">
        <b-card>
          <h1>{{ $t('Find Order') }}</h1>
          <hr />
          <b-overlay :show="sendingFindOrder">
            <b-form method="post" :action="getActionUrl.findOrder" @submit.prevent="findOrder">
              <b-form-group :label="$t('Order Number') +':'">
                <b-form-input
                    v-model.trim="form.sourceOrderId"
                    :placeholder="$t('Enter order number')"
                    name="sourceOrderId"
                    :readonly="sendingFindOrder"
                    @input="form.sourceOrderId = form.sourceOrderId.toUpperCase()"
                    required
                    autofocus
                ></b-form-input>
              </b-form-group>
              <b-input hidden v-model="form.sourceSlug" name="sourceSlug"></b-input>
              <hr />
              <b-button type="submit" variant="outline-success" :disabled="sendingFindOrder">{{ $t('Submit') }}</b-button>
              <b-alert class="mt-3" v-if="error" show="show" fade="fade" variant="danger">{{ error }}</b-alert>
            </b-form>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import findOrderMixin from "../mixins/findOrderMixin";

export default {
  mixins: [
    findOrderMixin
  ]
}
</script>