<template>
  <b-img @click="viewer(images)" class="w-100 px-3" :src="preview"></b-img>
</template>

<script>
import { api as viewerApi } from "v-viewer"

export default {
  name: "PhotoViewerPreview",
  props: {
    preview: null,
    images: {
      type: Array,
      required: false,
      default() {
        return [];
      }
    }
  },
  methods: {
    viewer(images) {
      viewerApi({
        images: images,
        options: {
          zoomRatio: 0.7
        }
      })
    }
  }
}
</script>

<style scoped>

</style>