<template>
  <b-form method="post" :action="actionApiUrl" @submit.prevent="updateShippingStatus($event)">
    <b-overlay :show="sendingShippingStatus">
      <b-table-simple class="mb-0" responsive="true" borderless small>
        <b-tr>
          <b-th>{{ $t('Carrier') }}:</b-th>
          <b-td class="text-break">
            <b-form-select v-if="edit" required v-model="model.carrier" :options="dictionaries.carriers" size="sm">
              <template #first>
                <b-form-select-option :value="null">{{ $t('Select one') }}</b-form-select-option>
              </template>
            </b-form-select>
            <span v-else>{{ getValueText(model.carrier, 'carriers') }}</span>
          </b-td>
        </b-tr>
        <b-tr>
          <b-th>{{ $t('Action') }}:</b-th>
          <b-td class="text-break">
            <b-form-select v-if="edit" required v-model="model.action" :options="dictionaries.requestActions" plaintext size="sm">
              <template #first>
                <b-form-select-option :value="null">{{ $t('Select one') }}</b-form-select-option>
              </template>
            </b-form-select>
            <span v-else>{{ getValueText(model.action, 'requestActions') }}</span>
          </b-td>
        </b-tr>
        <b-tr v-if="model.carrier !== 'COURIER'"><b-th>{{ $t('Tracking Number') }}:</b-th>
          <b-td class="text-break">
            <b-input v-if="edit" required v-model.trim="model.trackingNumber" size="sm"></b-input>
            <span v-else>{{ model.trackingNumber }}</span>
          </b-td>
        </b-tr>
        <b-tr v-if="edit"><b-td></b-td><b-td>
          <b-button type="submit" :variant="buttonVariant" size="sm">{{ $t('Update') }}</b-button>
        </b-td></b-tr>
      </b-table-simple>
    </b-overlay>
  </b-form>
</template>

<script>
import {postJsonData} from "../services/fetchApi";

export default {
  name: "RmaShippingStatus",
  props: ['value', 'buttonVariant', 'actionApiUrl', 'edit'],
  data() {
    return {
      model: {
        carrier: null,
        action: null,
        trackingNumber: null,
      },
      sendingShippingStatus: false,
    }
  },
  mounted() {
    this.$set(this, 'model', this.value);
  },
  methods: {
    updateShippingStatus(e) {
      const $this = this;
      $this.sendingShippingStatus = true;
      $this.$store.commit('setLoadingAnything', true);
      postJsonData(
          e.target.action,
          JSON.stringify($this.model)
      )
          .then(json => {
            $this.model = json.rma.shippingStatus;
            $this.$store.dispatch('getRmaHistory');

            $this.$store.commit('showToast', {
              message: $this.$t('Shipping Status has been updated'),
              title: $this.$t('Success'),
              type: 'success'
            });
          })
          .catch(error => {
            $this.$store.commit('showToast', {
              message: $this.$t('Shipping status has not been changed') + ': ' + (error ?? $this.$t('Unknown error')),
              title: $this.$t('Error'),
              type: 'danger'
            });
          })
          .finally(() => {
            $this.sendingShippingStatus = false;
            $this.$store.commit('setLoadingAnything', false);
          });
      return false;
    },
    getValueText(value, dictionary) {
      if (!this.dictionaries || !dictionary || !(dictionary in this.dictionaries)) {
        return '';
      }
      let found = this.dictionaries[dictionary].find(item => item.value === value);
      return found ? found.text : '';
    }
  },
  computed: {
    dictionaries: function () {
      if (!this.$store.state.dictionaries) {
        return [];
      }
      return this.$store.state.dictionaries;
    }
  },
  watch: {
    value() {
      this.$set(this, 'model', this.value);
    },
    model() {
      this.$emit('input', this.model);
    },
  }
}
</script>

<style scoped>

</style>