<template>
  <b-dropdown
      block
      :text="text"
      :variant="variant"
      @shown="$refs['filterOptions'].focus()"
      @hidden="filterOptions = ''"
  >
    <b-dropdown-form>
      <b-input v-model="filterOptions" size="sm" ref="filterOptions" autocomplete="off"></b-input>
    </b-dropdown-form>
    <b-dropdown-divider></b-dropdown-divider>
    <b-form-checkbox-group
        class="px-2"
        style="max-height: 200px; overflow-y: auto;"
        v-model="model"
        :options="showFilteredOptions"
        name="inputName"
        stacked
    ></b-form-checkbox-group>
  </b-dropdown>
</template>

<script>
export default {
  name: "MultiselectFilterDropdown",
  props: ['options', 'inputName', 'value', 'text', 'variant'],
  data() {
    return {
      model: this.value,
      filterOptions: ''
    }
  },
  computed: {
    showFilteredOptions() {
      if (!this.options) {
        return [];
      }
      return this.options.filter(item => item.text.toLowerCase().includes(this.filterOptions.toLowerCase()))
    }
  },
  watch: {
    value() {
      this.$set(this, 'model', this.value);
    },
    model() {
      this.$emit('input', this.model);
    },
  }
}
</script>

<style scoped>

</style>