import {postJsonData} from "../../services/fetchApi";

function getImHereUrl() {
    return process.env.VUE_APP_BACKEND_SERVER + 'i_am_here';
}

const imHere = {
    namespaced: true,
    state: {
        usersHaveOpenedRmaPage: null
    },
    mutations: {
        setUsers(state, users) {
            state.usersHaveOpenedRmaPage = users;
        },
    },
    getters: {
        getUsers: (state, getters) => {
            if (getters.getRma && state.usersHaveOpenedRmaPage && state.usersHaveOpenedRmaPage[getters.getRma.id]) {
                return state.usersHaveOpenedRmaPage[getters.getRma.id];
            } else {
                return [];
            }
        },
        getUserName(state, getters, rootState, rootGetters) {
            return rootGetters.getUserName;
        },
        getRma(state, getters, rootState, rootGetters) {
            return rootGetters.getRma
        }
    },
    actions: {
        getUsers(context) {
            postJsonData(getImHereUrl(), JSON.stringify({
                rmaId: context.getters.getRma.id,
                user: context.getters.getUserName
            }))
            .then(json => {
                let rmaUsers = {};
                rmaUsers[context.getters.getRma.id] = json;
                if (context.getters.getUsers.sort().join() !== json.sort().join()) {
                    context.commit('setUsers', rmaUsers);
                }
            }).catch(error => {
                if (process.env.NODE_ENV === 'development') {
                    context.commit('showToast', {
                        message: error,
                        title: 'Can not get user who is editing this RMA',
                        type: 'danger'
                    }, {root: true});
                }
            });
        },
    }
}

export default imHere;