<template>
  <b-container fluid="">
    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-list-group horizontal="" class="order-info">
          <b-list-group-item class="flex-grow-1">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="30%"></b-skeleton>
          </b-list-group-item>
          <b-list-group-item class="flex-grow-1">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="30%"></b-skeleton>
          </b-list-group-item>
          <b-list-group-item class="flex-grow-1">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="30%"></b-skeleton>
          </b-list-group-item>
          <b-list-group-item class="flex-grow-1">
            <b-skeleton width="85%"></b-skeleton>
            <b-skeleton width="55%"></b-skeleton>
            <b-skeleton width="70%"></b-skeleton>
            <b-skeleton width="30%"></b-skeleton>
          </b-list-group-item>
        </b-list-group>
        <br class="p-5">
        <b-card>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </template>
      <div v-if="!loading">
        <h1>{{ $t('New Return') }}: #{{ orderInfo.orderNumber }}</h1>
        <b-overlay :show="sendingCreateRma">
          <b-form method="post" :action="getActionUrl.createRma" @submit.prevent="createRma($event)">
            <b-card>
              <b-row>
                <b-col cols="3">
                  <div>{{ $t('Order ID') }}: {{ orderInfo.orderNumber }}</div>
                  <div>{{ $t('Order Date') }}: {{ dateTimeFormat(orderInfo.orderedDate) }}</div>
                  <div v-if="!orderInfo.isAvailableForReturnByDate">&#8252; {{ orderInfo.daysForReturn }} {{ $t('days for return are already past') }}</div>
                </b-col>
                <b-col cols="3">
                  <div>{{ $t('Customer Name') }}: {{ orderInfo.billingAddress.fullName }}</div>
                  <div>{{ $t('Email address') }}: {{ orderInfo.email }}</div>
                </b-col>
              </b-row>
            </b-card>
            <h4 class="mt-5">{{ $t('Step #1: Choose items you want to return') }}</h4>
            <b-table-simple>
              <b-tr>
                <b-th colspan="2">{{ $t('Item') }}</b-th>
                <b-th>{{ $t('Qty Ordered') }}</b-th>
                <b-th>{{ $t('Qty Shipped') }}</b-th>
                <b-th>{{ $t('Qty Returned') }}</b-th>
                <b-th>{{ $t('Price') }}</b-th>
                <b-th>{{ $t('To Return') }}</b-th>
              </b-tr>
              <b-tbody v-for="(product, productIndex) in listOfProduct.products" :key="product.id" :index="productIndex">
                <b-tr>
                  <b-td style="width: 150px">
                    <b-img class="w-100 px-3" :src="product.pictureUrl"></b-img>
                  </b-td>
                  <b-td>
                    <ul class="list-unstyled">
                      <li><b>{{ product.name }}</b></li>
                      <li>{{ $t('SKU') }}: {{ product.sku }}</li>
                      <li>{{ $t('Color') }}: {{ product.color.name }}</li>
                      <li>{{ $t('Size') }}: {{ product.size }}</li>
                    </ul>
                  </b-td>
                  <b-td>{{ product.quantityOrdered }}</b-td>
                  <b-td>{{ product.quantityShipped }}</b-td>
                  <b-td>{{ product.quantityReturned }}</b-td>
                  <b-td>{{ orderInfo.currency}} {{ product.finalPrice }}</b-td>
                  <b-td style="width: 200px">
                    <b-form-checkbox
                        v-model="model.products[productIndex].wantToReturn"
                        :disabled="!(product.quantityShipped && Number.parseInt(product.quantityShipped) > Number.parseInt(product.quantityReturned))"
                    >{{ $t('I want to return this') }}</b-form-checkbox>
                    <div v-if="product.isInStopList">&#8252; {{ $t('This product is in stop list') }}</div>
                    <div v-if="model.products[productIndex].wantToReturn">
                      <b-form-group :label="$t('Quantity to return')">
                        <b-form-select
                            v-model="model.products[productIndex].quantityToReturn"
                            :required="model.products[productIndex].wantToReturn">
                          <b-form-select-option
                              v-for="qty in (Number.parseInt(product.quantityShipped) - Number.parseInt(product.quantityReturned))"
                              :value="qty"
                              :key="qty">{{ qty }}
                          </b-form-select-option>
                        </b-form-select>
                      </b-form-group>
                      <b-form-group :label="$t('Reason Of Return')">
                        <b-form-select
                            v-model="model.products[productIndex].reasonOfReturn"
                            :options="returnReasons"
                            :required="model.products[productIndex].wantToReturn"
                        ></b-form-select>
                      </b-form-group>
                      <b-form-group :label="$t('Upload photo')" v-if="model.products[productIndex].reasonOfReturn === 'RECEIVED_DAMAGED'">
                        <b-form-file
                            multiple
                            accept="image/*"
                            v-model="model.products[productIndex].images"
                            :state="Boolean(model.products[productIndex].images)"
                            :placeholder="$t('Choose a file or drop it here...')"
                            :drop-placeholder="$t('Drop file here...')"
                            :required="model.products[productIndex].wantToReturn && model.products[productIndex].reasonOfReturn === 'RECEIVED_DAMAGED'"
                        ></b-form-file>
                      </b-form-group>
                      <b-form-group :label="$t('Resolution')" :required="model.products[productIndex].wantToReturn">
                        <b-form-radio-group
                            v-model="model.products[productIndex].resolution"
                            :options="resolutions"
                            :required="model.products[productIndex].wantToReturn"
                            plain
                            stacked
                        ></b-form-radio-group>
                      </b-form-group>
                      <b-form-group :label="$t('New Size')" v-if="model.products[productIndex].resolution === 'EXCHANGE'">
                        <OrderProductSizesForExchange
                            v-model="model.products[productIndex].newSize"
                            :sku="model.products[productIndex].sku"
                            :size="model.products[productIndex].size"
                            :region="orderInfo.region"
                            :country="orderInfo.shipments[0].address.countryCode"
                        ></OrderProductSizesForExchange>
                      </b-form-group>
                      <b-form-group :label="$t('New Color')" v-if="model.products[productIndex].resolution === 'EXCHANGE'">
                        <b-input v-model="model.products[productIndex].newColor" :required="model.products[productIndex].resolution === 'EXCHANGE' && !model.products[productIndex].newSize"></b-input>
                      </b-form-group>
                    </div>
                  </b-td>
                </b-tr>
                <b-tr v-if="product.returns.length > 0">
                  <b-td colspan="7">
                    <b-table-simple borderless small>
                      <tr v-for="(rma, indexReturn) in product.returns" :key="rma.id" :index="indexReturn">
                        <b-th><span v-if="indexReturn === 0">{{ $t('Open Returns') }}:</span></b-th>
                        <b-td>{{ rma.rmaNumber }}</b-td>
                        <b-td>{{ dateTimeFormat(rma.createdAt) }}</b-td>
                        <b-td>{{ $t(`rma_statuses.${rma.status}`) }}</b-td>
                        <b-td><b-link :to="{ name: 'rma', params: { rmaNumber: rma.rmaNumber } }">{{ $t('Show') }}</b-link></b-td>
                      </tr>
                    </b-table-simple>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <h4 class="mt-5">{{ $t('Step #2: Provide us additional information') }}</h4>
            <b-card>
              <b-row>
                <b-col cols="6">
                  <h3>{{ $t('Return Label') }}</h3>
                  <b-row>
                    <b-col cols="2">
                      <b-form-group :label="$t('Salutation') + '*'">
                        <b-form-select v-model="model.salutation" :options="salutations">
                          <template #first>
                            <b-form-select-option :value="null"></b-form-select-option>
                          </template>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="4">
                      <b-form-group :label="$t('First Name') + '*'">
                        <b-form-input v-model="model.firstName" required></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group :label="$t('Last Name') + '*'">
                        <b-form-input v-model="model.lastName" required></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group :label="$t('City') + '*'">
                        <b-form-input v-model="model.city" required></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col :cols="model.country !== 'US' ? 6 : 4">
                      <b-form-group :label="$t('Country') + '*'">
                        <b-form-select required v-model="model.country" :options="dictionaries.countries"></b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col cols="2" v-if="model.country === 'US'">
                      <b-form-group :label="$t('State') + '*'">
                        <b-form-select required v-model="model.stateCode" :options="dictionaries.usaStates"></b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="10">
                      <b-form-group :label="$t('Shipping Address') + '*'">
                        <b-form-input v-model="model.shippingAddress" required></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="2">
                      <b-form-group :label="$t('Zip') + '*'">
                        <b-form-input v-model="model.zipcode" required></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group :label="$t('Phone Number') + '*'">
                        <b-form-input v-model="model.phone" required></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12">
                      <b-form-group :label="$t('Your comment')">
                        <b-form-textarea
                            v-model="model.comment"
                            :placeholder="$t('If you want to tell us something')"
                            rows="4"
                            no-resize
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col cols="6" v-if="model.askBankAccount && selectedEvenOneRefund">
                  <h3>{{ $t('Payment Information') }}</h3>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group :label="$t('Name and Surname (Account Holder)') + (orderInfo.region !== '04' ? ' *' : '')">
                        <b-form-input v-model="model.paymentFullName" :required="orderInfo.region !== '04'"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group :label="$t('Bank Name') + (orderInfo.region !== '04' ? ' *' : '')">
                        <b-form-input v-model="model.paymentBankName" :required="orderInfo.region !== '04'"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="6">
                      <b-form-group :label="$t('IBAN') + (orderInfo.region !== '04' ? ' *' : '')">
                        <b-form-input v-model="model.paymentIban" :required="orderInfo.region !== '04'"></b-form-input>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group :label="$t('BIC') + (orderInfo.region !== '04' ? ' *' : '')">
                        <b-form-input v-model="model.paymentBic" :required="orderInfo.region !== '04'"></b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mt-5">
                <b-col cols="12" class="text-center">
                  <b-button size="lg" type="submit" variant="success" :disabled="!selectedEvenOneProductToReturn || sendingCreateRma">
                    <b-spinner :class="{'spinner-border mx-3': 1, 'd-none': !sendingCreateRma}"></b-spinner>
                    <span :class="{'d-none': sendingCreateRma}">{{ $t('Create Return') }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-form>
        </b-overlay>
      </div>
    </b-skeleton-wrapper>
  </b-container>
</template>

<script>

import {getJsonData, postData} from "../services/fetchApi";
import {dateTimeFormat} from "../services/DateTimeFormat";
import {Salutation as SalutationEnum} from "../enum/Salutation";
import {ReturnReason as ReturnReasonEnum} from "../enum/ReturnReason";
import {Resolution as ResolutionEnum} from "../enum/Resolution";
import OrderProductSizesForExchange from "./OrderProductSizesForExchange";

export default {
  components: {OrderProductSizesForExchange},
  data() {
    return {
      loading: true,
      sendingCreateRma: false,
      orderId: null,
      orderInfo: null,
      orderNumber: null,
      model: {
        orderId: '',
        orderNumber: '',
        deliveryService: '',
        salutation: '',
        firstName: '',
        lastName: '',
        shippingAddress: '',
        city: '',
        zipcode: '',
        country: '',
        state: '',
        phone: '',
        comment: '',
        askBankAccount: false,
        paymentFullName: null,
        paymentBankName: null,
        paymentIban: null,
        paymentBic: null,
        products: [],
      },
    }
  },
  methods: {
    createRma(e) {
      const $this = this;
      $this.sendingCreateRma = true;

      let request = JSON.parse(JSON.stringify(this.model));
      request.products = request.products.filter(product => product.wantToReturn);

      const fd = new FormData();
      fd.append('json', JSON.stringify(request));
      this.model.products.filter(product => product.wantToReturn).map((product) => {
        product.images.map((image, j) => {fd.append('files[' + product.id + '][' + j + ']', image);});
      });

      postData(
          e.target.action,
          fd
      ).then((json) => {
        $this.$router.push({name: 'rma', params: {rmaNumber: json.rmaNumber}});
        $this.$store.commit('showToast', {
          message: $this.$t('Return has been created'),
          title: $this.$t('Success'),
          type: 'success'
        });
      }).catch(error => {
        $this.$store.commit('showToast', {
          message: $this.$t('Can not create return') + ': ' + (error ?? $this.$t('Unknown error')),
          title: $this.$t('Error'),
          type: 'danger'
        });
      })
      .finally(() => {
        $this.sendingCreateRma = false;
      });
    },
    initModel: function () {
      const $this = this;
      this.model.orderNumber = this.orderInfo.orderNumber;
      this.model.salutation = this.orderInfo.shipments[0].address.salutation;
      this.model.firstName = this.orderInfo.shipments[0].address.fullName.slice(0, this.orderInfo.shipments[0].address.fullName.indexOf(' '));
      this.model.lastName = this.orderInfo.shipments[0].address.fullName.slice(this.orderInfo.shipments[0].address.fullName.indexOf(' '), this.orderInfo.shipments[0].address.fullName.length);
      this.model.city = this.orderInfo.shipments[0].address.city;
      this.model.country = this.orderInfo.shipments[0].address.countryCode;
      this.model.shippingAddress = this.orderInfo.shipments[0].address.address1;
      if (this.orderInfo.shipments[0].address.address2) {
        this.model.shippingAddress += ' ' + this.orderInfo.shipments[0].address.address2;
      }
      this.model.stateCode = this.orderInfo.shipments[0].address.stateCode;
      this.model.zipcode = this.orderInfo.shipments[0].address.postalCode;
      this.model.phone = this.orderInfo.shipments[0].address.phone;
      this.model.askBankAccount = this.orderInfo.paymentInfo.askBankAccount;

      this.orderInfo.shipments.forEach(function (shipment) {
        shipment.products.forEach(function (product, index) {
          $this.$set($this.model.products, index, Object.assign({}, {
              id: product.productId,
              sku: product.sku,
              size: product.size,
              wantToReturn: false,
              quantityToReturn: 1,
              reasonOfReturn: 'TOO_SMALL',
              resolution: 'STORE_CREDIT',
              images: [],
              newSize: null,
              newColor: null
            })
          );
        });
      });
    },
    dateTimeFormat: function (datetime) {
      return dateTimeFormat(datetime);
    },
    getOrder: function () {
      const $this = this;
      return getJsonData(process.env.VUE_APP_BACKEND_SERVER + 'api/orderInfo/' + $this.orderId)
        .then(json => {
          $this.orderInfo = json.order;
          $this.$nextTick(function () {
            $this.initModel();
          });
        }).catch(error => {
            $this.$store.commit('showToast', {
              message: $this.$t('Can not find the order') + ': ' + (error ? error : $this.$t('Unknown error')),
              title: $this.$t('Error'),
              type: 'danger'
            });
        });
    }
  },
  mounted() {
    const $this = this;
    Promise.all([$this.getOrder()]).then(() => {
      $this.loading = false;
    })
  },
  created() {
    this.orderId = this.$route.params.id
  },
  computed: {
    getActionUrl: function() {
      return {
        createRma: process.env.VUE_APP_BACKEND_SERVER + 'api/returns'
      }
    },
    selectedEvenOneProductToReturn: function() {
      return this.model.products.filter(product => product.wantToReturn).length > 0;
    },
    selectedEvenOneRefund: function() {
      return this.model.products.filter(product => product.resolution === 'REFUND').length > 0;
    },
    listOfProduct() {
      return this.orderInfo.shipments.length > 0 ? this.orderInfo.shipments[0] : null;
    },
    dictionaries: function () {
      if (!this.$store.state.dictionaries) {
        return [];
      }
      return this.$store.state.dictionaries;
    },
    salutations: function () {
      const $this = this;
      return Object.entries(SalutationEnum).map(val => ({value: val[0], text: $this.$t(`salutations.${val[1]}`)}));
    },
    returnReasons() {
      const $this = this;
      return Object.keys(ReturnReasonEnum).filter(key => key !== ReturnReasonEnum.OTHER).map(key => ({value: key, text: $this.$t(`return_reasons.${key}`)}));
    },
    resolutions() {
      const $this = this;
      return Object.keys(ResolutionEnum).map(key => ({value: key, text: $this.$t(`resolutions.${key}`)}));
    },
  }
}
</script>
