<template>
  <b-container>
    <b-row>
      <b-col>
        <b-link :href="getPublicRmsUrl()" target="_blank">{{ $t('Customer Part') }}</b-link>
      </b-col>
      <b-col>
        <b-link :to="{ name: 'returns' }">{{ $t('Backoffice Part') }}</b-link>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  name: "LinkToPublicRms",
  methods: {
    getPublicRmsUrl() {
      return process.env.VUE_APP_PUBLIC_FRONT_URL;
    }
  }
}
</script>