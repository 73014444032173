<template>
  <b-container>
    <b-row class="h-100">
      <b-col class="my-auto" md="6" offset-md="3">
        <b-card>
          <form class="border-1 rounded-4" @submit.prevent="login">
            <div class="text-center"><img :class="{'animatedAuthLogo': sendingLoginForm}" :src="require('@/assets/logo.png')" alt="PP RMS" /></div>
            <hr />
            <b-overlay :show="sendingLoginForm">
              <b-form-group :label="$t('Email') + ':'">
                <b-form-input
                    type="email"
                    v-model="loginForm.email"
                    name="email"
                    :placeholder="$t('Email')"
                    required
                    autofocus
                ></b-form-input>
              </b-form-group>
              <b-form-group :label="$t('Password') + ':'">
                <b-form-input
                  type="password"
                  v-model="loginForm.password"
                  name="password"
                  :placeholder="$t('Password')"
                  required
                ></b-form-input>
              </b-form-group>
              <hr/>
              <b-button type="submit" variant="outline-success" :disabled="sendingLoginForm">{{ $t('Login') }}</b-button>
            </b-overlay>
          </form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {postJsonData} from "../services/fetchApi";
import {clearJwtLocalStorage, getPayloadFromJwt, setJwtLocalStorage} from "../services/JwtPayload";

export default {
  data(){
    return {
      sendingLoginForm: false,
      loginForm: {
        email: "",
        password: ""
      }
    }
  },
  methods: {
    login: function () {
      const $this = this;
      this.sendingLoginForm = true;

      clearJwtLocalStorage();
      this.$store.commit('setUser', null);

      postJsonData(
        process.env.VUE_APP_BACKEND_SERVER + 'api/login_check',
        JSON.stringify(this.loginForm)
      )
        .then(json => {
          if (json.token) {
            setJwtLocalStorage(json.token);
            const user = getPayloadFromJwt(json.token);
            $this.$store.commit('setUser', user);
            $this.$store.commit('showToast', {
              message: $this.$t('You are logged in'),
              title: $this.$t('Hi, {username}!', {username: user.username}),
              type: 'primary'
            });
            $this.$router.push({name: 'returns'});
          } else {
            $this.$store.commit('showToast', {
              message: $this.$t('Can not auth. Please try again.'),
              title: $this.$t('Can not authorize'),
              type: 'danger'
            });
          }
        })
        .catch(error => {
          $this.$store.commit('showToast', {
            message: error,
            title: $this.$t('Can not authorize'),
            type: 'danger'
          });
        })
        .finally(() => {
          $this.sendingLoginForm = false;
        });
    }
  }
}
</script>
<style>
 .animatedAuthLogo {
   animation-duration: 3s;
   animation-name: rotateLogo;
   animation-iteration-count: infinite;
 }

 @keyframes rotateLogo {
   from {
   transform: rotateY(0deg)
   }

   to {
   transform: rotateY(360deg)
   }
 }
</style>