<template>
  <b-tr :class="{'bg-light': selected}">
    <b-td class="col-checkbox" @click="selectTd($event)">
      <b-form-checkbox v-model="selected"></b-form-checkbox>#{{ value.productNumber }}
    </b-td>
    <b-td class="col-preview">
      <div style="width: 100px">
        <PhotoViewerPreview :preview="value.picture" :images="generateListOfProductImages"></PhotoViewerPreview>
      </div>
    </b-td>
    <b-td class="col-sku">
      <div class="product-title">{{ value.title }}</div>
      <DashboardProductLink :article="value.article" :color-code="value.colorCode" :product-url="value.url"></DashboardProductLink>
      <div class="text-muted">{{ $t('Size') }}: {{ value.size }} {{ $t('Color') }}: {{ value.color }} <b-badge v-if="value.isCities" variant="warning" class="ml-3 text-uppercase">cites</b-badge></div>
      <div v-if="userHasRole('ROLE_BACK_OFFICE')" class="text-secondary mt-1"><b-checkbox v-model="value.isFranchise">{{ $t('Franchise') }}</b-checkbox></div>
      <div v-if="value.spedimexReturnDocNo && value.spedimexReturnItem" class="mt-1"><b-badge :variant="value.spedimexReturnItem.quantityReceived === null ? 'secondary' : 'primary'" :title="'Spedimex DocNo: ' + value.spedimexReturnDocNo"><span>{{ value.spedimexReturnDocNo }}</span> {{ value.spedimexReturnItem.ean }}#{{ value.spedimexReturnItem.lineNo }}</b-badge></div>
      <div v-if="userHasRole('ROLE_BACK_OFFICE') && value.intexReturnPos" class="mt-1"><b-badge variant="secondary" :title="'Intex RmsIdent: ' + value.intexReturnPos.rmsIdent + ' created at ' + value.intexReturnPos.createdAt + ' by ' + value.intexReturnPos.createdBy">Intex RmsIdent: <span>{{ value.intexReturnPos.rmsIdent }}</span></b-badge></div>
    </b-td>
    <b-td v-if="userHasRole('ROLE_CAN_SEE_FINANCE_INFO')" class="col-price">
      <div class="text-muted" :id="'price-adjustment-' + value.id"><small><del>{{ currency }}&nbsp;{{ value.basePrice.toFixed(2) }}</del></small></div>
      <b-popover :target="'price-adjustment-' + value.id" triggers="hover" placement="top">
        <template #title=""></template>
        <div v-for="discount in value.discounts" :key="discount.price_adjustment_id">
          <div><b>{{ discount.item_text }} / {{ discount.promotion_id }}</b></div>
          <div v-if="discount.applied_discount"><b>{{ discount.applied_discount.type }}:</b> {{ discount.applied_discount.amount }}</div>
          <div><b>{{ $t('Price') }}:</b> {{ discount.price.toFixed(2) }}</div>
        </div>
      </b-popover>
      <div v-if="value.amountBeforeDiscount" class="text-muted"><small><del>{{ currency }}&nbsp;{{ value.amountBeforeDiscount.toFixed(2) }}</del></small></div>
      <div class="text-nowrap">{{ currency }}&nbsp;{{ value.amountToReturn.toFixed(2) }}</div>
      <div class="text-muted" v-if="region === '02'"><i><small>{{ value.amountToReturnWithoutTax.toFixed(2) }} + {{ value.tax.toFixed(2) }}</small></i></div>
    </b-td>
    <b-td class="col-reason">
      <b-input-group class="flex-nowrap">
        <b-form-select
            :disabled="!userHasRole('ROLE_CAN_SEE_FINANCE_INFO')"
            v-model="value.reasonOfReturn"
            :options="returnReasons"
            :title="value.reasonOfReturn"
        ></b-form-select>
        <b-input-group-append>
          <PhotoViewerButton :images="value.customerPhotos"></PhotoViewerButton>
        </b-input-group-append>
      </b-input-group>
    </b-td>
    <b-td v-if="userHasRole('ROLE_CAN_SEE_FINANCE_INFO')" class="col-resolution">
      <b-select
          v-model="value.resolution"
          :options="resolutions"
          :size="value.resolution === 'EXCHANGE' ? 'sm' : ''"
      ></b-select>
      <b-input-group v-if="value.resolution === 'EXCHANGE'" size="sm">
        <b-input v-model="value.newSize" :placeholder="$t('Size')"></b-input>
        <b-input v-model="value.newColor" :placeholder="$t('Color')"></b-input>
      </b-input-group>
    </b-td>
    <b-td class="col-action-exchange">
      <template v-if="toShowExchangeAction">
        <b-input size="sm" v-model="value.reservationNumber" :placeholder="$t('Reservation')" :required="!(value.status === 'REQUESTED' || value.status === 'RECEIVED' || value.status === 'DECLINED' || value.status === 'IN_PROCCESS')"></b-input>
        <b-input-group>
          <b-select size="sm" v-model="value.reservationWarehouse" :options="dictionaries.warehouses" :required="!(value.status === 'REQUESTED' || value.status === 'RECEIVED' || value.status === 'DECLINED' || value.status === 'IN_PROCCESS')">
            <template #first>
              <b-form-select-option value="" disabled>-- {{ $t('Warehouse') }} --</b-form-select-option>
            </template>
          </b-select>
          <b-select size="sm" v-model="value.reservationStatus" :options="reservationStatuses" :required="!(value.status === 'REQUESTED' || value.status === 'RECEIVED' || value.status === 'DECLINED' || value.status === 'IN_PROCCESS')">-- {{ $t('Status') }} --</b-select>
        </b-input-group>
      </template>
    </b-td>
    <b-td v-if="userHasRole('ROLE_CAN_SEE_FINANCE_INFO')" class="col-action-refund">
      <template v-if="toShowRefundAction">
        <b-input-group size="sm" :prepend="currency" class="flex-nowrap">
          <b-input number type="number" step=".01" min="0.00" v-model.number="value.approvedRefundAmount" :placeholder="$t('Approved Amount')" required></b-input>
        </b-input-group>
        <b-input-group>
          <b-input v-model="value.refundReference" :placeholder="$t('Refund Reference')" size="sm"></b-input>
        </b-input-group>
      </template>
    </b-td>
    <b-td v-if="userHasRole('ROLE_CAN_SEE_FINANCE_INFO')" class="col-action-voucher">
      <template v-if="toShowVoucherAction">
        <b-input-group size="sm" class="text-nowrap">
          <b-input-group-prepend is-text>
            <b-form-checkbox
                switch
                v-model="value.voucherStatus"
                class="mb-n1"
                value="ENABLED"
                unchecked-value="DISABLED"
                :state="value.status !== 'PROCESSED' ? null : value.voucherStatus === 'ENABLED'"
                :required="value.status === 'PROCESSED'"
            ></b-form-checkbox>
          </b-input-group-prepend>
          <b-input
              v-model="value.voucherNumber"
              :placeholder="$t('Voucher')"
              :required="value.status === 'PROCESSED'"
          ></b-input>
        </b-input-group>
        <b-input-group size="sm" :prepend="currency">
          <b-input number
                   type="number"
                   step=".01"
                   min="0.00"
                   v-model.number="value.voucherAmount"
                   :placeholder="$t('Amount')"
                   :required="value.status === 'APPROVED' || value.status === 'PROCESSED'"
          ></b-input>
        </b-input-group>
      </template>
    </b-td>
    <b-td class="col-condition">
      <b-input-group class="flex-nowrap">
        <b-form-select
            v-model="value.condition"
            :options="conditions"
            :required="value.status === 'RECEIVED'"
        ></b-form-select>
        <b-input-group-append>
          <WhPhotos
              v-model="value.warehousePhotos"
              :rmaNumber="rmaNumber"
              :itemId="value.id"
              :needToUpload="(value.status !== 'RECEIVED' || (value.status === 'RECEIVED' && value.customerPhoto && value.customerPhotos.length > 0)) ? null : !((!value.warehousePhotos || value.warehousePhotos.length === 0) && (value.operation !== 'ITEM_OK_IN_001' && value.operation !== 'IN_001'))"
          ></WhPhotos>
          <PhotoViewerButton :images="value.warehousePhotos"></PhotoViewerButton>
        </b-input-group-append>
      </b-input-group>
    </b-td>
    <b-td class="col-operation"><b-form-select v-model="value.operation" :options="operations"></b-form-select></b-td>
    <b-td class="col-status"><b-form-select v-model="value.status" :options="rmaStatuses"></b-form-select></b-td>
  </b-tr>
</template>
<script>
import DashboardProductLink from "./DashboardProductLink";
import PhotoViewerButton from "./PhotoViewerButton";
import WhPhotos from "./WhPhotos";
import PhotoViewerPreview from "./PhotoViewerPreview";
import UserCanMixin from "../mixins/UserCanMixin";
import {ReturnReason as ReturnReasonEnum} from "../enum/ReturnReason";
import {Resolution as ResolutionEnum} from "../enum/Resolution";
import {ReservationStatus as ReservationStatusEnum} from "../enum/ReservationStatus";
import {Condition as ConditionEnum} from "../enum/Condition";
import {Operation as OperationEnum} from "../enum/Operation";
import {RmaStatus as RmaStatusEnum} from "../enum/RmaStatus";

export default {
  mixins: [UserCanMixin],
  props: {
    rmaId: {
      type:String,
      required: true
    },
    rmaNumber: {
      type:String,
      required: true
    },
    currency: {
      type: String,
      required: true,
    },
    region: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      required: true,
    },
    selectedAll: {
      type: Boolean
    },
    value: {
      type: Object,
      required: true,
      default: function () {
        return {
          id: null,
          productNumber: null,
          article: null,
          sku: null,
          colorCode: null,
          size: null,
          basePrice: null,
          discounts: [],
          amountBeforeDiscount: null,
          amountToReturn: null,
          amountToReturnWithoutTax: null,
          tax: null,
          customerPhotos: [],
          resolution: null,
          newSize: null,
          newColor: null,
          warehousePhotos: [],
          condition: null,
          isFranchise: false,
          spedimexReturnDocNo: null,
          spedimexReturnItem: null,
          intexReturnPos: null
        }
      }
    }
  },
  data() {
    return {
      selected: false
    }
  },
  methods: {
    selectTd(e) {
      if ('TD' === e.target.tagName) {
        this.selected = !this.selected;
      }
    }
  },
  computed: {
    dictionaries: function () {
      if (!this.$store.state.dictionaries) {
        return [];
      }
      return this.$store.state.dictionaries;
    },
    toShowExchangeAction() {
      return (!!this.value.reservationNumber) || this.value.resolution === 'EXCHANGE';
    },
    toShowVoucherAction() {
      return (!!this.value.voucherAmount || (this.value.resolution === 'STORE_CREDIT' && (this.value.status === 'APPROVED' || this.value.status === 'PROCESSED')));
    },
    toShowRefundAction() {
      return (!!this.value.approvedRefundAmount || (this.value.resolution === 'REFUND' && (this.value.status === 'APPROVED' || this.value.status === 'PROCESSED')));
    },
    returnReasons() {
      const $this = this;
      return Object.keys(ReturnReasonEnum).map(key => ({value: key, text: $this.$t(`return_reasons.${key}`), disabled: key === ReturnReasonEnum.OTHER}));
    },
    resolutions() {
      const $this = this;
      return Object.keys(ResolutionEnum).map(key => ({value: key, text: $this.$t(`resolutions.${key}`)}));
    },
    reservationStatuses() {
      const $this = this;
      return Object.keys(ReservationStatusEnum).map(key => ({value: key, text: $this.$t(`reservation_statuses.${key}`)}));
    },
    conditions() {
      const $this = this;
      return Object.keys(ConditionEnum).map(key => ({value: key, text: $this.$t(`conditions.${key}`)}));
    },
    operations() {
      const $this = this;
      return Object.keys(OperationEnum).map(key => ({value: key, text: $this.$t(`operations.${key}`)}));
    },
    rmaStatuses() {
      const $this = this;
      return Object.keys(RmaStatusEnum).map(key => ({value: key, text: $this.$t(`rma_statuses.${key}`)}));
    },
    generateListOfProductImages() {
      const $this = this;
      const sizes = [
        {
          'name': 'main',
          'prefix': 'm',
        },
        {
          'name': 'outfit',
          'prefix': 'o',
        },
        {
          'name': 'rollover',
          'prefix': 'r',
        },
        {
          'name': 'large',
          'prefix': 'sf',
        },
        {
          'name': 'large',
          'prefix': 'sb',
        },
        {
          'name': 'large',
          'prefix': 'mf',
        },
        {
          'name': 'large',
          'prefix': 'mb',
        },
        {
          'name': 'large',
          'prefix': 'd1',
        },
        {
          'name': 'large',
          'prefix': 'd2',
        },
        {
          'name': 'large',
          'prefix': 'd3',
        },
      ];
      return sizes.map(size => $this.value.picture.replace('_m.', '_' + size.prefix + '.').replace('/main/', '/' + size.name + '/'))
    }
  },
  watch: {
    selectedAll(newVal) {
        console.log(this.selectedAll, newVal)
        this.$set(this, 'selected', this.selectedAll);
    },
    value() {
      this.$emit('input', this.value)
    },
    selected() {
      this.$emit('selectProduct', this.value.id)
    },
    toShowExchangeAction(val) {
      if (val === false) {
        this.$set(this.value, 'reservationNumber', null);
        this.$set(this.value, 'reservationWarehouse', null);
        this.$set(this.value, 'reservationStatus', null);
      }
    },
    toShowVoucherAction(val) {
      if (val === false) {
        this.$set(this.value, 'voucherAmount', null);
        this.$set(this.value, 'voucherNumber', null);
        this.$set(this.value, 'voucherStats', null);
      }

      if (val === true) {
        this.$set(this.value, 'voucherAmount', this.value.amountToReturn);
      }
    },
    toShowRefundAction(val) {
      if (val === false) {
        this.$set(this.value, 'approvedRefundAmount', null);
      }

      if (val === true) {
        this.$set(this.value, 'approvedRefundAmount', this.value.amountToReturn);
      }
    }
  },
  components: {
    DashboardProductLink,
    PhotoViewerButton,
    WhPhotos,
    PhotoViewerPreview
  }
}
</script>

<style scoped>
  .product-title {
    font-size: 0.85em;
  }
</style>