<template>
  <div>
    <b-form method="POST" :action="getActionUrl" @submit.stop.prevent="saveComment($event)">
      <b-row>
        <b-col cols="6">
          <b-textarea class="w-100 h-100" name="comment" required v-model="model.text"></b-textarea>
        </b-col>
        <b-col class="flex-shrink-1 flex-grow-0 text-nowrap">
          <b-form-group class="mb-0">
            <b-form-checkbox-group
                v-model="model.notify"
                :options="listToNotify"
                name="to_notify[]"
                button-variant="outline-primary"
                stacked
                switches
            ></b-form-checkbox-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-button type="submit" variant="outline-success" size="lg" class="px-5" :disabled="commentIsSending">
            <span :class="{'spinner-border': 1, 'd-none': !commentIsSending}" role="status" aria-hidden="true"></span>
            <span :class="{'d-none': commentIsSending}">{{ $t('Add') }}</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <div class="pt-4">
      <div v-if="getComments.length > 0">
        <b-row class="font-weight-bold">
          <b-col cols="2">{{ $t('User') }}</b-col>
          <b-col cols="2">{{ $t('Date/Time') }}</b-col>
          <b-col cols="6">{{ $t('Comment') }}</b-col>
          <b-col cols="2">{{ $t('Notificated') }}</b-col>
        </b-row>

        <b-row v-for="comment in getComments" :key="comment.id">
          <b-col cols="2">{{ comment.createdBy }}</b-col>
          <b-col cols="2">{{ dateTimeFormat(comment.createdAt) }}</b-col>
          <b-col cols="6">{{ comment.text }}</b-col>
          <b-col cols="2">{{ comment.notify }}</b-col>
        </b-row>
      </div>
      <div v-else>
        <b-alert show="show">{{ $t('No Comments') }}</b-alert>
      </div>
    </div>
  </div>
</template>
<script>
import {postJsonData} from "../services/fetchApi";
import UserCanMixin from "../mixins/UserCanMixin";

export default {
  mixins: [UserCanMixin],
  props: ['rmaId'],
  data() {
    return {
      comments: [],
      commentIsSending: false,
      listToNotify: [],
      model: {
        text: '',
        notify: []
      }
    }
  },
  methods: {
    saveComment(e) {
      const $this = this;
      this.commentIsSending = true;
      postJsonData(
          e.target.action,
          JSON.stringify($this.model)
      ).then(() => {
          this.model.notify = [];
          this.model.text = null;

          $this.$bvToast.toast('Comment added', {
            title: $this.$t('Success'),
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: true,
            toaster: 'b-toaster-bottom-right'
          });

          $this.$store.dispatch('getRmaComments');
        })
      .catch(error => {
        $this.$bvToast.toast(error.message, {
          title: $this.$t('Error'),
          variant: 'danger',
          autoHideDelay: 5000,
          appendToast: true,
          toaster: 'b-toaster-bottom-right'
        });
      })
      .finally(() => {
        $this.commentIsSending = false;
      });
    },
    dateTimeFormat: function (datetime) {
      return (new Date(datetime)).toLocaleString();
    },
  },
  created() {
    this.$store.dispatch('getRmaComments');
    if (this.userHasRole('ROLE_WAREHOUSE')) {
      this.model.notify.push('bo');
    }

    this.listToNotify.push({text: this.$t('Notify BO'), value: 'bo'});
    if (this.userHasRole('ROLE_BACK_OFFICE')) {
      this.listToNotify.unshift({text: this.$t('Notify SPX'), value: 'spx'});
      this.listToNotify.unshift({text: this.$t('Notify FL'), value: 'fashion'});
    }
  },
  computed: {
    getComments: function () {
      return this.$store.getters.getRmaComments ?? [];
    },
    getRmaCommentsLoading: function () {
      return this.$store.getters.getRmaCommentsLoading;
    },
    getRmaNumber: function () {
      return this.$store.getters.getRma.rmaNumber;
    },
    getActionUrl: function () {
      return process.env.VUE_APP_BACKEND_SERVER + 'api/returns/' + this.getRmaNumber + '/comments';
    }
  }
}
</script>