const ReturnReason = Object.freeze({
    TOO_BIG: 'TOO_BIG',
    TOO_SMALL: 'TOO_SMALL',
    DAMAGED_WHEN_WEARING: 'DAMAGED_WHEN_WEARING',
    NOT_EXPECTED: 'NOT_EXPECTED',
    RECEIVED_DAMAGED: 'RECEIVED_DAMAGED',
    QUALITY_NOT_SATISFYING: 'QUALITY_NOT_SATISFYING',
    WRONG_ITEM: 'WRONG_ITEM',
    CHANGE_MIND: 'CHANGE_MIND',
    OTHER: 'OTHER'
});

export {ReturnReason};