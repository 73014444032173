const JWT_TOKEN_LS_NAME = 'token';

function getPayloadFromJwt(token) {
    if (!token) {
        return null;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

function getJwtTokenLocalStorage() {
    return localStorage.getItem(JWT_TOKEN_LS_NAME);
}

function setJwtLocalStorage(val) {
    localStorage.setItem(JWT_TOKEN_LS_NAME, val);
}

function clearJwtLocalStorage() {
    localStorage.removeItem(JWT_TOKEN_LS_NAME)
}

export {getPayloadFromJwt, getJwtTokenLocalStorage, setJwtLocalStorage, clearJwtLocalStorage};